import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import paymentDoneImg from "../img/payment-done.png";
import Button from "react-bootstrap/Button";
import Footer from "../component/footer";
import Header from "../component/header";
import LoaderComponent from "../component/loader";
import { useNavigate } from 'react-router-dom';
import useApi from "../Hooks/useApi";

const PaymentConfirmation = () => {
  const [successMsg,setSuccessMsg] = useState("")


  const navigate = useNavigate();

  const {
    data: cartClearResponse,
    error: cartClearError,
    loading: cartClearLoading,
    makeApiCall: cartClearData,
  } = useApi("cart-clear", "GET", []);


  useEffect(() => {
      // Call the backend API to clear the cart after payment
      const clearCart = async () => {
          try {
            cartClearData()
          } catch (error) {
              console.error('Error clearing the cart:', error);
          }
      };

      clearCart();
  }, [navigate]);


  useEffect(() => {
    setSuccessMsg("Thank you for successful enroll for the premium course. Please go to Student Dashboard under your account name in top right menu and click on Premium videos to access the course. If you face any issue or have any additional query, please email to info@uplatz.com or call/whatsapp on +44 7836212635.")
  }, []);

  return (
    <>
      <LoaderComponent loader={cartClearLoading} />
      <Header />
      <Container fluid className="d-flex flex-column min-vh-100">
        <Container className="my-auto py-5">
          <Row className="justify-content-center align-items-center text-center">
            <Col xs={12} md={6}>
              <img
                src={paymentDoneImg}
                alt="Payment Successful"
                style={{ maxWidth: "100%" }}
              />
            </Col>
            <Col xs={12} md={6}>
              <div className="message-container">
                <h2 className="text-success mb-3">Payment Successful!</h2>
                <p className="success-msg text-muted">
                  {successMsg}
                </p>
                <Button
                  variant="primary"
                  className="mt-4 px-5 py-2"
                  href="/my-course-list" // Link to the dashboard
                >
                  Go to My Course
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
    </>
  );
};

export default PaymentConfirmation;
