import React, { useState } from 'react';

const ReadMore = ({ text }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => setIsReadMore(!isReadMore);

  return (
    <p>
      {isReadMore ? text.slice(0, 150) + '...' : text}
      <span onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer' }}>
        {isReadMore ? ' Read More' : ' Show Less'}
      </span>
    </p>
  );
};

export default ReadMore;
