import { render, screen } from "@testing-library/react";
import "../App.css";
import Header from "../component/header";
import Footer from "../component/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import dataScience from "../img/data-analysis.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import courseImg from "../img/course-img.jpg";
import Pagination from "react-bootstrap/Pagination";
import useApi from "../Hooks/useApi";
import { useEffect, useState } from "react";
import { currencySymbols, PICURL, sortList,convertCurrency ,currencySymbolConvert} from "../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import MyPagination from "../component/MyPagination";
import LoaderComponent from "../component/loader";

function Listpage() {
  let navigate = useNavigate();

  const useData = localStorage.getItem("user-data");
  const user = useData ? JSON.parse(useData) : null;

  const { data, error, loading, makeApiCall } = useApi(
    "get-online-it-courses",
    "POST",
    []
  );
  
  const {
    data: getCategorydata,
    error: getCategoryError,
    loading: getCategoryLoading,
    makeApiCall: fetchGetCategory,
  } = useApi("get-categories", "GET", []);

  const [couserListData, setCourseListData] = useState([]);

  const handleCourseDetail = (course) => {
    navigate("/course-detail", {
      state: {
        course_id: course?.id,
      },
    });
  };

  const [categoryData, setCategoryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleCategory = (categoryId) => {
    setCategoryId(categoryId);
  };

  const [categoryId, setCategoryId] = useState([]);
  const [priceFilter, setPriceFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [durationFilter, setDurationFilter] = useState([]);
  const [featuresFilter, setFeaturesFilter] = useState([]);
  const [selectedSort, setSelectedSort] = useState("");
  console.log("selectedSort", selectedSort);

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value); // Capture the selected value
  };

  console.log(
    "categoryData",
    categoryId
  );

  const handleFilter = (id, type) => {
    if (type === "category") {
      setCategoryId((prevCategoryId) => {
        if (prevCategoryId.includes(id)) {
          // If the ID is already selected, remove it
          return prevCategoryId.filter((category) => category !== id);
        } else {
          // Otherwise, add the new ID
          return [...prevCategoryId, id];
        }
      });
    } else if (type === "price") {
      setPriceFilter((prevPrice) => {
        if (prevPrice.includes(id)) {
          // If the price filter is already selected, remove it
          return prevPrice.filter((price) => price !== id);
        } else {
          // Otherwise, add the new price filter
          return [...prevPrice, id];
        }
      });
    } else if (type === "type") {
      setTypeFilter((prevType) => {
        if (prevType.includes(id)) {
          // If the price filter is already selected, remove it
          return prevType.filter((price) => price !== id);
        } else {
          // Otherwise, add the new price filter
          return [...prevType, id];
        }
      });
    } else if (type === "duration") {
      setDurationFilter((prevDuration) => {
        if (prevDuration.includes(id)) {
          // If the price filter is already selected, remove it
          return prevDuration.filter((price) => price !== id);
        } else {
          // Otherwise, add the new price filter
          return [...prevDuration, id];
        }
      });
    } else if (type === "features") {
      setFeaturesFilter((prevFeatures) => {
        if (prevFeatures.includes(id)) {
          // If the price filter is already selected, remove it
          return prevFeatures.filter((price) => price !== id);
        } else {
          // Otherwise, add the new price filter
          return [...prevFeatures, id];
        }
      });
    }
  };

  const clearAllFilter = () => {
    setDurationFilter([]);
    setTypeFilter([]);
    setPriceFilter([]);
    setCategoryId([]);
    setFeaturesFilter([]);
  };

  useEffect(() => {
    makeApiCall({
      is_premium: 1,
      category_id: categoryId,
      price: priceFilter,
      type: typeFilter,
      duration: durationFilter,
      features: featuresFilter,
      sort: selectedSort,
    });

    fetchGetCategory();
  }, [
    selectedSort,
    featuresFilter,
    categoryId,
    priceFilter,
    typeFilter,
    durationFilter,
    fetchGetCategory,
    makeApiCall,
  ]);

  useEffect(() => {
    if (data) {
      setCourseListData(data?.data);
      setTotalCount(data?.data?.total);
    }
    if (getCategorydata) {
      setCategoryData(getCategorydata);
    }
    console.log("data : ", data, ", error :", error, ", loading : ", loading);
  }, [data, error, getCategorydata, loading]);

  const handlePageChange = (page) => {
    // Fetch data for the selected page
    makeApiCall({
      is_premium: 1,
      page,
      category_id: categoryId,
      price: priceFilter,
      type: typeFilter,
      duration: durationFilter,
      features: featuresFilter,
      sort: selectedSort,
    }); // Call your API with the new page number
  };

  const [visibleCount, setVisibleCount] = useState(6); // State to manage the number of categories displayed

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 6); // Increase the visible count by 6
  };

  const categoriesToDisplay = categoryData?.data?.slice(0, visibleCount) || [];

  return (
    <>
      <Container fluid>
        <div classNavme="BetList">
          <Header />
        </div>

        {/* <div className="GreyBG py-5">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={12} lg={10}>
                <div className="cat-icon d-inline-block">
                  <img src={dataScience} style={{ maxWidth: "50px" }} />
                </div>
                <div className="cat-text d-inline-block">
                  <h2 className="blue">Data Science</h2>
                  <p>
                    Build expertise in data streaming, business analytics, data
                    visualization, data analysis, data science programming, data
                    engineering, data architecture, predictive analytics, and
                    more.
                  </p>
                </div>
              </Col>
              <Col className="text-center text-lg-end" xs={12} md={12} lg={2}>
                {!user ? (
                  <Link to={"/Signup"}>
                    {" "}
                    <Button
                      href="/course-detail"
                      className="me-2 w-lg-100 rounded-1 btn-red text-uppercase f-17 fw-700"
                      variant="primary"
                      size="lg"
                    >
                      {" "}
                      Enroll NOW{" "}
                    </Button>{" "}
                  </Link>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </div> */}

        <Container className="py-5">
          <Row className="align-items-center">
            <Col xs={12} md={9}>
              <h2 className="f-20 fw-700 text-uppercase">All Courses</h2>
              <p>
                <span className="results">{totalCount} Results</span>
                <span className="ratings">
                  4.7
                  <span className="stars">
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <span>(1065 Reviews)</span>
                  </span>
                </span>
              </p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Select
                className="greySelect"
                aria-label="Default select example"
                onChange={handleSortChange}
              >
                <option>Sort by Most Popular</option>
                {Object.entries(sortList)?.map(([key, value]) => (
                  <option value={value}>{value}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12} md={3}>
              <h4 className="f-17 fw-700">
                Filters{" "}
                <span
                  className="float-end f-12 mt-2 fw-normal text-uppercase"
                  style={{ cursor: "pointer" }}
                  onClick={clearAllFilter}
                >
                  <u>Clear ALL</u>
                </span>
              </h4>
              <div className="br-box">
                {/* <div className="f-box mb-2">
                  <h4 className="f-title">Level</h4>
                  <Form>
                    <Form.Check label="Beginner" name="group1" />
                    <Form.Check label="Intermediate" name="group1" />
                    <Form.Check label="Advanced" name="group1" />
                  </Form>
                </div> */}
                <div className="f-box mb-2">
                  <h4 className="f-title">Price</h4>
                  <Form>
                    <Form.Check
                      checked={priceFilter.includes("Free")} // Bind checked state
                      label="Free"
                      name="group1"
                      onClick={() => handleFilter("Free", "price")}
                    />
                    <Form.Check
                      checked={priceFilter.includes("Paid")} // Bind checked state
                      label="Paid"
                      name="group1"
                      onClick={() => handleFilter("Paid", "price")}
                    />
                  </Form>
                </div>
                <div className="f-box mb-2">
                  <h4 className="f-title">Categories</h4>
                  <Form>
                    {categoriesToDisplay.map((category, index) => (
                      <Form.Check
                        key={index} // Add a unique key for each element
                        label={category?.super_category}
                        name={category?.super_category}
                        onClick={() => handleFilter(category?.id, "category")}
                        checked={categoryId.includes(category?.id)} // Bind checked state
                      />
                    ))}
                  </Form>
                  {visibleCount < (categoryData?.data?.length || 0) && (
                    <span
                      className="f-12"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowMore}
                    >
                      <u>View More...</u>
                    </span>
                  )}
                </div>

                {/* <div className="f-box mb-2">
                  <h4 className="f-title">Type</h4>
                  <Form>
                    <Form.Check
                      checked={typeFilter.includes("Career Path")} // Bind checked state
                      label="Career Path"
                      name="group1"
                      onClick={() => handleFilter("Career Path", "type")}
                    />
                    <Form.Check
                      checked={typeFilter.includes("Skill Path")} // Bind checked state
                      label="Skill Path"
                      name="group1"
                      onClick={() => handleFilter("Skill Path", "type")}
                    />
                    <Form.Check
                      checked={typeFilter.includes("Courses")} // Bind checked state
                      label="Courses"
                      name="group1"
                      onClick={() => handleFilter("Courses", "type")}
                    />
                  </Form>
                </div> */}
                <div className="f-box mb-2">
                  <h4 className="f-title">Duration</h4>
                  <Form>
                    <Form.Check
                      checked={durationFilter.includes("Hours")} // Bind checked state
                      label="Hours"
                      name="group1"
                      onClick={() => handleFilter("Hours", "duration")}
                    />
                    <Form.Check
                      checked={durationFilter.includes("Days")} // Bind checked state
                      label="Days"
                      name="group1"
                      onClick={() => handleFilter("Days", "duration")}
                    />
                    <Form.Check
                      checked={durationFilter.includes("Weeks")} // Bind checked state
                      label="Weeks"
                      name="group1"
                      onClick={() => handleFilter("Weeks", "duration")}
                    />
                    <Form.Check
                      checked={durationFilter.includes("Month")} // Bind checked state
                      label="Month"
                      name="group1"
                      onClick={() => handleFilter("Month", "duration")}
                    />
                  </Form>
                </div>
                {/* <div className="f-box mb-2">
                  <h4 className="f-title">Features</h4>
                  <Form>
                    <Form.Check
                      label="Subtitles"
                      name="group1"
                      checked={featuresFilter.includes("Subtitles")} // Bind checked state
                      onClick={() => handleFilter("Subtitles", "features")}
                    />
                    <Form.Check
                      label="Quizzes"
                      name="group1"
                      checked={featuresFilter.includes("Quizzes")} // Bind checked state
                      onClick={() => handleFilter("Quizzes", "features")}
                    />
                    <Form.Check
                      label="Coding Exercises"
                      name="group1"
                      checked={featuresFilter.includes("Coding Exercises")} // Bind checked state
                      onClick={() =>
                        handleFilter("Coding Exercises", "features")
                      }
                    />
                    <Form.Check
                      label="Practice Tests"
                      name="group1"
                      checked={featuresFilter.includes("Practice Tests")} // Bind checked state
                      onClick={() => handleFilter("Practice Tests", "features")}
                    />
                  </Form>
                </div> */}
              </div>
            </Col>
            <Col xs={12} md={9}>
              {loading ? (
                <LoaderComponent loader={loading || getCategoryLoading} />
              ) : (
                <ul className="text-start productList list-page mb-3">
                  {couserListData?.data?.length > 0
                    ? couserListData?.data?.map((course_list) => {
                        return (
                          <>
                            <li onClick={() => handleCourseDetail(course_list)}>
                              <div className="imgs">
                                <img
                                  src={PICURL + course_list?.picture}
                                  style={{ maxWidth: "100%" }}
                                />
                                <span className="tag">Courses</span>
                              </div>
                              <h4>{course_list?.group_name} </h4>
                              <p className="author">2,486,255 Viewers</p>
                              <p className="ratings">
                                {course_list?.rating}
                                <span className="stars">
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <span>
                                    ({course_list?.total_review} Reviews)
                                  </span>
                                </span>
                              </p>
                              <p className="price">
                                {currencySymbolConvert(course_list?.price_currency)} {convertCurrency(course_list?.price_currency,course_list?.price)}
                              </p>
                            </li>
                          </>
                        );
                      })
                    : ""}
                </ul>
              )}
              <MyPagination
                current_page={couserListData?.current_page}
                last_page={couserListData?.last_page}
                next_page_url={couserListData?.next_page_url}
                prev_page_url={couserListData?.prev_page_url}
                onPageChange={handlePageChange}
              />
            </Col>
          </Row>
        </Container>

        <div classNavme="BetList">
          <Footer />
        </div>
      </Container>
    </>
  );
}

export default Listpage;
