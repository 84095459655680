import Pagination from 'react-bootstrap/Pagination';

const MyPagination = ({ current_page, last_page, next_page_url, prev_page_url, onPageChange }) => {
  const handlePageChange = (page) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const renderPaginationItems = () => {
    const totalPagesToShow = 5; // Max number of pages to show at once
    let startPage = Math.max(1, current_page - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(last_page, startPage + totalPagesToShow - 1);

    if (endPage - startPage < totalPagesToShow - 1) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    const paginationItems = [];

    // Add first page and ellipsis if current range starts after the first page
    if (startPage > 1) {
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    // Generate the page numbers between startPage and endPage
    for (let page = startPage; page <= endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          active={page === current_page}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    // Add last page and ellipsis if the current range ends before the last page
    if (endPage < last_page) {
      if (endPage < last_page - 1) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      paginationItems.push(
        <Pagination.Item key={last_page} onClick={() => handlePageChange(last_page)}>
          {last_page}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <Pagination className="text-center">
      <Pagination.Prev 
        onClick={() => handlePageChange(current_page - 1)} 
        disabled={!prev_page_url} 
      />
      {renderPaginationItems()}
      <Pagination.Next 
        onClick={() => handlePageChange(current_page + 1)} 
        disabled={!next_page_url} 
      />
    </Pagination>
  );
};

export default MyPagination;
