import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import paymentDoneImg from "../img/failed_done.png";
import Button from "react-bootstrap/Button";
import Footer from "../component/footer";
import Header from "../component/header";
import LoaderComponent from "../component/loader";
import { useNavigate } from 'react-router-dom';
import useApi from "../Hooks/useApi";

const PaymentCancel = () => {
  const [successMsg,setSuccessMsg] = useState("")


  useEffect(() => {
    setSuccessMsg("Your payment has been cancelled. Please try again. If you face any issue or have any additional query, please email to info@uplatz.com or call/whatsapp on +44 7836212635.")
  }, []);

  return (
    <>
      <Header />
      <Container fluid className="d-flex flex-column min-vh-100">
        <Container className="my-auto py-5">
          <Row className="justify-content-center align-items-center text-center">
            <Col xs={12} md={6}>
              <img
                src={paymentDoneImg}
                alt="Payment Cancel"
                style={{ maxWidth: "100%" }}
              />
            </Col>
            <Col xs={12} md={6}>
              <div className="message-container">
                <h2 className="text-danger mb-3">Payment Cancel!</h2>
                <p className="success-msg text-muted">
                  {successMsg}
                </p>
                <Button
                  variant="primary"
                  className="mt-4 px-5 py-2"
                  href="/my-cart" // Link to the dashboard
                >
                  Go to My Cart
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
    </>
  );
};

export default PaymentCancel;
