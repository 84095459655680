import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import signImg from "../img/signup-img.png";
import Button from "react-bootstrap/Button";
import Footer from "../component/footer";
import Header from "../component/header";
import Form from "react-bootstrap/Form";
import useApi from "../Hooks/useApi";
import LoaderComponent from "../component/loader";
import * as Yup from "yup";

const MyProfile = () => {
  // Yup validation schema
  const UpdateProfileSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .required("Phone number is required"),
    user_type: Yup.string().required("User Type is required"),
    gender: Yup.string().required("Gender is required"),
    image: Yup.mixed(),
  });

  const { data, error, loading, makeApiCall } = useApi("get-profile", "POST", []);

  const {
    data: updateResponse,
    error: updateError,
    loading: updateLoade,
    makeApiCall: updateProfile,
  } = useApi("update-profile", "POST", []);

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone_number: "",
    user_type: "",
    gender: "",
    image: "",
  });

  const [imagePreview, setImagePreview] = useState(""); // For image preview
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    makeApiCall();
  }, []);

  useEffect(() => {
    if (data) {
      setProfileData({
        name: data?.data?.fullname || "",
        email: data?.data?.email || "",
        phone_number: data?.data?.phone_number || "",
        user_type: data?.data?.user_type || "",
        gender: data?.data?.gender || "",
        image: data?.data?.picture || "",
      });
    }
  }, [data, error, loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileData({ ...profileData, image: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validate the profile data using Yup
      await UpdateProfileSchema.validate(profileData, { abortEarly: false });

      // Prepare form data for API request
      const formData = new FormData();
      formData.append("name", profileData.name);
      formData.append("email", profileData.email);
      formData.append("phone_number", profileData.phone_number);
      formData.append("user_type", profileData.user_type);
      formData.append("gender", profileData.gender);
      if (profileData.image) {
        formData.append("image", profileData.image);
      }

      // Clear validation errors if validation passes
      setValidationErrors({});

      // Call API to update profile
      await updateProfile(formData, true); // Pass true for isFormData
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        // Collect Yup validation errors
        const errors = validationError.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setValidationErrors(errors);
      }
    }
  };

  useEffect(() => {
    if (updateResponse) {
      setMessage("Profile updated successfully!");
    } else if (updateError) {
      setMessage("Failed to update profile.");
    }
  }, [updateResponse, updateError]);

  return (
    <>
      <LoaderComponent loader={updateLoade} />
      <Container fluid>
        <div className="BetList">
          <Header />
        </div>
        <Container className="py-5">
          <Row className="align-items-center">
            <Col xs={12} md={7}>
              <img src={signImg} style={{ maxWidth: "100%" }} />
            </Col>
            <Col xs={12} md={5}>
              <Form onSubmit={handleSubmit}>
                {message && (
                  <Alert variant={updateError ? "danger" : "success"}>
                    {message}
                  </Alert>
                )}

<div className="myImg">
<Form.Group className="cam">
                  <Form.Control
                    type="file"
                    aria-label="Image"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </Form.Group>

                {imagePreview && (
                  <div className="img-preview">
                    <img
                      src={imagePreview}
                      alt="Profile Preview"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                )}

</div>
                <Form.Control
                  className="f-12 formInput my-3"
                  placeholder="Full Name"
                  aria-label="Full Name"
                  name="name"
                  value={profileData.name}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.name}
                </Form.Control.Feedback>

                <Form.Control
                  className="f-12 formInput my-3"
                  placeholder="Email"
                  aria-label="Email"
                  name="email"
                  value={profileData.email}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.email}
                </Form.Control.Feedback>

                <Form.Control
                  className="f-12 formInput my-3"
                  placeholder="Mobile"
                  aria-label="Mobile"
                  name="phone_number"
                  value={profileData.phone_number}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.phone_number}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.phone_number}
                </Form.Control.Feedback>

                <Form.Control
                  className="f-12 formInput my-3"
                  placeholder="User Type"
                  aria-label="User Type"
                  name="user_type"
                  value={profileData.user_type}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.user_type}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.user_type}
                </Form.Control.Feedback>

                <Form.Select
                  className="f-12 formInput my-3"
                  aria-label="Gender"
                  name="gender"
                  value={profileData.gender}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.gender}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {validationErrors.gender}
                </Form.Control.Feedback>

              

                <Button
                  type="submit"
                  className="me-2 w-100 rounded-1 btn-red text-uppercase f-17 fw-700"
                  variant="primary"
                  size="lg"
                  disabled={updateLoade}
                >
                  {updateLoade ? "Updating..." : "Submit"}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <div className="BetList">
          <Footer />
        </div>
      </Container>
    </>
  );
};

export default MyProfile;
