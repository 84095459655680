import { render, screen } from '@testing-library/react';
import '../App.css';
import HeaderIn from '../component/header-user';
import Footer from '../component/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import dataScience from '../img/data-analysis.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import courseImg from '../img/course-img.jpg';
import Pagination from 'react-bootstrap/Pagination';

function MyLIst() {

    return (

        <>
         <Container fluid>
         <div classNavme='BetList'>
              <HeaderIn />
        </div>  

<div className='GreyBG py-5'>
    <Container>
        <Row className='align-items-center'>
            <Col xs={12} md={12} lg={12}>
               
                <div className='cat-text d-inline-block'>
                    <h2 className='blue'>My Learning</h2>
                  
                </div>
            </Col>
          
        </Row>
    </Container>
</div>


<Container className='py-5'>
    
        <Row className='mt-3'>
            <Col xs={12} md={3}>
                <div className='br-box'>
                    <ul>
                        <li><a className='active' href='#'>All Courses</a></li>
                        <li><a href='#'>My Lists</a></li>
                        <li><a href='#'>Wish list</a></li>
                        <li><a href='#'>Archived</a></li>
                        <li><a href='#'>Learning Tools</a></li>
                    </ul>
                </div>
            </Col>
            <Col xs={12} md={9}>
            <h4 className='f-17 fw-700 mb-3'>All Courses </h4>

            <ul className='text-start productList list-page mb-3'>
                <li>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>Dr. Angelas Yu. Developers</p>
                    <Button href='/ClassDetails' className='me-2 mt-2 w-100 rounded-1 f-14' variant="primary" >Start Course</Button>

                </li>
                <li>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>Dr. Angelas Yu. Developers</p>
                    <Button href='/ClassDetails' className='me-2 mt-2 w-100 rounded-1 f-14' variant="primary" >Start Course</Button>

                </li>
                <li>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>Dr. Angelas Yu. Developers</p>
                    <Button href='/ClassDetails' className='me-2 mt-2 w-100 rounded-1 f-14' variant="primary" >Start Course</Button>

                </li>
                <li>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>Dr. Angelas Yu. Developers</p>
                    <Button href='/ClassDetails' className='me-2 mt-2 w-100 rounded-1 f-14' variant="primary" >Start Course</Button>

                </li>
                
                <li>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>Dr. Angelas Yu. Developers</p>
                    <Button href='/ClassDetails' className='me-2 mt-2 w-100 rounded-1 f-14' variant="primary" >Start Course</Button>

                </li>
                <li>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>Dr. Angelas Yu. Developers</p>
                    <Button href='/ClassDetails' className='me-2 mt-2 w-100 rounded-1 f-14' variant="primary" >Start Course</Button>

                </li>
             
            </ul>

       
            </Col>
        </Row>
</Container>
<div className='related'>
    <Container className='py-5'>
        <h2 className='blue f-20 fw-700 text-uppercase'>Related Courses</h2>
        <ul className='d-flex text-start productList'>
                <li className='flex-fill'>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>2,486,255 Viewers</p>
                    <p className='ratings'>
                        4.7  
                        <span className='stars'>
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <span>(1065 Reviews)</span>
                        </span>
                    </p>
                    <p className='price'>$77.98</p>
                </li>
                <li className='flex-fill'>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>2,486,255 Viewers</p>
                    <p className='ratings'>
                        4.7  
                        <span className='stars'>
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <span>(1065 Reviews)</span>
                        </span>
                    </p>
                    <p className='price'>$77.98</p>
                </li>
                <li className='flex-fill'>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>2,486,255 Viewers</p>
                    <p className='ratings'>
                        4.7  
                        <span className='stars'>
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <span>(1065 Reviews)</span>
                        </span>
                    </p>
                    <p className='price'>$77.98</p>
                </li>
                <li className='flex-fill'>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>2,486,255 Viewers</p>
                    <p className='ratings'>
                        4.7  
                        <span className='stars'>
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <span>(1065 Reviews)</span>
                        </span>
                    </p>
                    <p className='price'>$77.98</p>
                </li>
                <li className='flex-fill'>
                    <div className='imgs'>
                        <img src={courseImg}  style={{maxWidth: '100%'}}/>
                        <span className='tag'>Courses</span>
                    </div>
                    <h4>Career Advise from someone of the biggest Names in </h4>
                    <p className='author'>2,486,255 Viewers</p>
                    <p className='ratings'>
                        4.7  
                        <span className='stars'>
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <span>(1065 Reviews)</span>
                        </span>
                    </p>
                    <p className='price'>$77.98</p>
                </li>
             
            </ul>
    </Container>
</div>

<div classNavme='BetList'>
              <Footer />
        </div>  
        </Container>
        </>
    );
}

export default MyLIst;