import React, { useEffect, useState } from 'react';
import { render, screen } from '@testing-library/react';
import '../App.css';
import * as Yup from 'yup';
import Header from '../component/header';
import Footer from '../component/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col, Table, Alert } from 'react-bootstrap';
import signImg from '../img/signup-img.png';
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import useApi from '../Hooks/useApi';
import { Link ,useNavigate} from "react-router-dom";

const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string().required('Full name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});


function Signup() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullname, setFullname] = useState('');
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { data, error, loading, makeApiCall } = useApi('register', 'POST', []);
    const useData = localStorage.getItem('user-data');
    const navigate = useNavigate()
    useEffect(()=>{
        if(useData){
            navigate('/')
        }
    },[])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(false);
        setErrors({});

        try {
            await RegistrationSchema.validate({ fullname, email, password }, { abortEarly: false });
            console.log('Form submitted successfully with values:', { email, password, fullname });
            // Make the API call
            await makeApiCall({ fullname, email, password });
            // Check if the API call was successful
            if (data) {
                setSubmitted(true);
                setShowSuccess(true);
            }
            console.log("data : ", data, ', error :', error, ', loading : ', loading);
            setSubmitted(true);
            // You can redirect or call an API here
        } catch (err) {
            const validationErrors = {};
            err.inner.forEach((error) => {
                validationErrors[error.path] = error.message;
            });
            setErrors(validationErrors);
        }
    };

    useEffect(() => {
        if (submitted && showSuccess) {
            const timer = setTimeout(() => {
                setShowSuccess(false);
                setSubmitted(false); // Optionally reset the submitted state
            }, 3000); // Adjust the time (in milliseconds) as needed

            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [submitted, showSuccess]);

    return (
        <>
            <Container fluid>
                <div className='BetList'>
                    <Header />
                </div>
                <Container className='py-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} md={7}>
                            <img src={signImg} style={{ maxWidth: '100%' }} />
                        </Col>
                        <Col xs={12} md={5}>
                            <Form onSubmit={handleSubmit}>
                                <h2 className='blue'>Sign up and start learning</h2>
                                <Form.Control
                                    className={`f-12 formInput my-3  ${errors.fullname ? 'is-invalid' : ''}`}
                                    placeholder="Full Name"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                />
                                {errors.fullname && <div className="invalid-feedback">{errors.fullname}</div>}
                                <Form.Control
                                    className={`f-12 formInput my-3  ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder="Email Address"
                                    aria-label="Email"
                                    aria-describedby="basic-addon1"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                <Form.Control
                                    className={`f-12 formInput my-3  ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder="Password"
                                    aria-label="Username"
                                    type='password'
                                    aria-describedby="basic-addon1"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                <p>
                                    <Form.Check name='tips' className='f-13' aria-label="option 1" label="Send me special offers, personalized recommendations, and 
                    learning tips."/>

                                </p>
                                {error && <Alert variant="danger">Error: {error.errors?.email?.[0]}</Alert>}
                                {submitted && !error && <Alert variant="success">Signup successful!</Alert>}
                                <Button type="submit" className='me-2 w-100 rounded-1 btn-red text-uppercase f-17 fw-700' variant="primary" size="lg" disabled={loading} >{loading ? 'Loading...' : 'Sign up'}</Button>
                                <p className='f-12 text-center m-2'>By signing up, you agree to our Terms of Use and Privacy Policy.</p>
                                <Button className='rounded-1 mt-3 brwse-alredy w-100 text-' variant="secondary" size="lg">Already have an account? <Link to='/login'>Login In</Link></Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div className='BetList'>
                    <Footer />
                </div>
            </Container>
        </>
    );
}

export default Signup;