import { render, screen } from "@testing-library/react";
import "../App.css";
import Header from "../component/header";
import Footer from "../component/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import uplatz_logo from "../img/uplatzlogo.png";

import { Container, Row, Col, Table } from "react-bootstrap";
import BestDeller from "../img/best-seller.png";
import Tutor from "../img/tutor.png";
import calendar from "../img/calendar.png";
import globe from "../img/globe.png";
import certi from "../img/certi.png";
import Demand from "../img/demand.png";
import Access from "../img/access.png";

import Assignment from "../img/assignment.png";
import Infinity from "../img/infinity.png";
import Article from "../img/article.png";
import Trophy from "../img/trophy.png";
import Download from "../img/download.png";
import ticks from "../img/ticks.png";
import videos from "../img/video.png";
import courseImg from "../img/course-img.jpg";
import PlaySmall from "../img/play-small.png";
import ReadBefore from "../img/read-before.png";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faEye } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStars } from "@fortawesome/free-regular-svg-icons";
import { faCircleQuestion, faClock } from "@fortawesome/free-regular-svg-icons";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";
import useApi from "../Hooks/useApi";
import { useLocation ,useNavigate} from "react-router-dom";
import { ContentDisplay, currencySymbols, PICURL, VIDEOURL ,DynamicAccordionFromHtml,DynamicAccordionFromHtml2} from "../utils/helper";
import LoaderComponent from "../component/loader";
import ReactPlayer from 'react-player';

function CourseDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const useData = localStorage.getItem('user-data');
  const user = useData ? JSON.parse(useData) : null;

  const { data, error, loading, makeApiCall } = useApi(
    "online-it-course-detail",
    "POST",
    []
  );
  const {
    data: relatedDataResponse,
    error: relatedDataError,
    loading: relatedDataLoading,
    makeApiCall: fetchRelatedData,
  } = useApi("get-related-courses", "GET", []);

  const {
    data: cartData,
    error: cartError,
    loading: cartLoading,
    makeApiCall: saveCart,
  } = useApi("add-to-cart", "POST", []);

  const [couserDetailData, setCourseDetailData] = useState([]);
  const [relatedCourse, setRelatedCourse] = useState([]);
  const [hasVideo, setHasVideo] = useState(false);
  const [mediaURL, setMediaURL] = useState("");
  useEffect(() => {
    fetchRelatedData();
  }, []);

  useEffect(() => {
    if(relatedDataResponse){
      setRelatedCourse(relatedDataResponse.data);
    }
  }, [relatedDataResponse]);
  useEffect(() => {
    // Make the API call
    //makeApiCall({ course_id: 7 });
    console.log("location.state.course_id : ", location.state.course_id);
    
    makeApiCall({ course_id: location.state.course_id , user_id : user?.data?.id });
  }, []);

  useEffect(() => {
    if (data) {
      setCourseDetailData(data?.data);
      if(data?.data.is_paid==true){
        navigate("/premium-video-courses-detail", {
          state: {
            course_id: data?.data?.id,
          },
        });

      }
      if (data?.data.top_first_video) {
        setHasVideo(true);
        setMediaURL(
          hasVideo
            ? PICURL + data?.data.picture
            : VIDEOURL + data?.data.top_first_video.video
        );
      } else {
        setMediaURL(PICURL + data?.data.picture);
      }
    }

    console.log("data : ", data, ", error :", error, ", loading : ", loading);
  }, [data, error, loading]); // Run this effect when data, error, or loading change


  // Handle adding to cart
  const handleAddToCart = async () => {
    try {

      if (user) {
        console.log("Adding to cart...");
        await saveCart({ post_id: location.state.course_id });
      }else{
        navigate('/login')
      }
    } catch (error) {
      console.error("API call failed", error);
    }
  };

  // UseEffect to handle navigation after cartData is successfully updated
  useEffect(() => {
    if (cartData?.status) {
      console.log("Redirecting to cart...");
      navigate("/my-cart"); // Redirect only when the cartData status is true
    }
  }, [cartData, navigate]); // This effect will run when cartData changes

  return (
    <>
      <LoaderComponent loader={loading} />
      <Container fluid>
        <div classNavme="BetList">
          <Header />
        </div>

        <div className="CouseTop">
          <Container className="py-5">
            <Row>
              <Col xs={12} md={8}>
                <h2 className="blue f-25 fw-700 text-uppercase">
                  {couserDetailData?.group_name}
                </h2>
                <p>{couserDetailData?.sub_title}</p>
                <div className="cDetails">
                  <span className="Bseller">
                    <img src={BestDeller} /> Bestseller
                  </span>
                  <span className="ratings">
                    {couserDetailData?.rating}
                    <span className="stars">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <span>({couserDetailData?.total_review} Reviews)</span>
                    </span>
                  </span>

                  <span className="totalStudent mt-3 mt-md-0">
                    {couserDetailData?.users} Students
                  </span>
                </div>

                <p className="tutor">
                  <img
                    style={{
                      height: "30px",
                      width: "auto",
                      border: "1px solid",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                    src={uplatz_logo}
                    alt=""
                  />
                  By <b>Uplatz</b> In{" "}
                  <b>{couserDetailData?.category_name?.category}</b>
                </p>
                <p className="Others">
                  <span>
                    {" "}
                    <img src={calendar} /> Last updated{" "}
                    {couserDetailData?.post_time}{" "}
                  </span>
                  <span>
                    {" "}
                    <img src={globe} /> English{" "}
                  </span>
                  <span className="mt-3 mt-md-0">
                    {" "}
                    <img src={certi} /> Certificate Course{" "}
                  </span>
                </p>
              </Col>
              <Col xs={12} md={4}>
                &nbsp;
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="py-5">
          <Row>
            <Col xs={12} md={8} className="CourseDetails">
              <Tabs
                fill
                defaultActiveKey="overview"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="overview" title="About">
                  <div className="detailBox">
                    <h3>Description</h3>
                    <ContentDisplay content={couserDetailData?.details} />
                    <span className="more">Show More</span>
                  </div>
                  <div className="detailBox">
                    <h3>This course includes:</h3>
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <p>
                          <img src={Demand} /> 34 hours on-demand video
                        </p>
                        <p>
                          <img src={Assignment} /> Assignments
                        </p>
                        <p>
                          <img src={Article} /> 2 articles
                        </p>
                        <p>
                          <img src={Download} /> 35 downloadable resources
                        </p>
                      </Col>
                      <Col xs={12} md={6}>
                        <p>
                          <img src={Access} /> Access on mobile and TV
                        </p>
                        <p>
                          <img src={Infinity} /> Full lifetime access
                        </p>
                        <p>
                          <img src={Trophy} /> Certificate of completion
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="details" title="Objectives">
                  <div className="detailBox mb-0">
                    <h3>Objectives</h3>
                    <p>
                      <ContentDisplay content={couserDetailData?.jobs} />
                    </p>
                  </div>
                </Tab>
                <Tab eventKey="content" title="Syllabus">
                  <div className="detailBox">
                    <h3>Course Content</h3>
                    <p>
                      <ContentDisplay content={couserDetailData?.syllabus} />
                    </p>
                  </div>
                </Tab>

                <Tab eventKey="instructor" title="Certification">
                    <div className="detailBox">
                      <h3>Certification</h3>
                      <p>
                        <ContentDisplay content={couserDetailData?.certification}  />
                      </p>
                    </div>
                </Tab>
                <Tab eventKey="review" title="Career & Jobs">
                  <div className="detailBox">
                    <h3>Career & Jobs</h3>
                    <p>
                      <ContentDisplay
                        content={couserDetailData?.career_path_and_jobs}
                      />
                    </p>
                  </div>
                </Tab>
                <Tab eventKey="review mb-0" title="Review">
                  <div className="detailBox reviewBox">
                    <h3>Review</h3>
                    <Row className="mx-0">
                      <Col xs={12} md={3} className="px-0">
                        <div className="total-rating">
                          <p className="redTitle">Course Ratings</p>
                          <p className="totalRate">{couserDetailData?.rating}</p>
                          <p className="ratingStar">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </p>
                        </div>
                      </Col>
                      <Col xs={12} md={9} className="pe-0">
                        <p className="d-flex mb-2">
                          <span className="ratStars">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                          </span>
                          <span className="flex-grow-1 rate-strip">
                            <span>
                              <span style={{ width: "63%" }}></span>
                            </span>
                          </span>
                          <span>63%</span>
                        </p>
                        <p className="d-flex mb-2">
                          <span className="ratStars">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                          </span>
                          <span className="flex-grow-1 rate-strip">
                            <span>
                              <span style={{ width: "29%" }}></span>
                            </span>
                          </span>
                          <span>29%</span>
                        </p>
                        <p className="d-flex mb-2">
                          <span className="ratStars">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                          </span>
                          <span className="flex-grow-1 rate-strip">
                            <span>
                              <span style={{ width: "15%" }}></span>
                            </span>
                          </span>
                          <span>15%</span>
                        </p>
                        <p className="d-flex mb-2">
                          <span className="ratStars">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                          </span>
                          <span className="flex-grow-1 rate-strip">
                            <span>
                              <span style={{ width: "12%" }}></span>
                            </span>
                          </span>
                          <span>12%</span>
                        </p>
                        <p className="d-flex mb-2">
                          <span className="ratStars">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                            <FontAwesomeIcon
                              icon={faStars}
                              className="d-inline-block me-1 align-text-top"
                            />
                          </span>
                          <span className="flex-grow-1 rate-strip">
                            <span>
                              <span style={{ width: "5%" }}></span>
                            </span>
                          </span>
                          <span>02%</span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Col>
            <Col xs={12} md={4}>
              <div className="courseRight">
                <div className="vBox mb-2" style={{ height: "215px;" }}>
                  {!hasVideo ? (
                    <img
                      src={mediaURL}
                      alt="Course Media"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <video
                      controls
                      oncontextmenu="return false;"
                      controlsList="nodownload"
                      poster={PICURL + couserDetailData?.top_first_video.thumb}
                      style={{ maxWidth: "100%", height: "215px" }}
                    >
                      <source src={mediaURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {/* <img src={PICURL + couserDetailData?.picture} style={{ maxWidth: '100%' }} /> */}
                </div>
                {/* <div className='priceBox'>
                                    <span className='price'>$69.99</span>
                                    <span className='old-price'>$69.99</span>
                                    <span className='daysLeft'><FontAwesomeIcon icon={faClock} /> 3 days left</span>
                                </div> */}

                <Button className='me-2 rounded-1 btn-red' variant="primary" size="lg" onClick={handleAddToCart}>Add to cart</Button>
                {/* <Button className='rounded-1 btn-buy bg-transparent' variant="secondary" size="lg">Buy Now</Button> 
                <p className='moneyBack'>30-Day Money-Back Guarantee</p>*/}
                <ListGroup>
                  <ListGroup.Item>
                    Duration{" "}
                    <span className="float-end">
                      {couserDetailData?.duration} Included
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Enrolled{" "}
                    <span className="float-end">
                      {couserDetailData?.user} Students
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Lectures <span className="float-end">50 Lectures</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Skill Level <span className="float-end">Basic Level</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Language <span className="float-end">English</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Quizez <span className="float-end">20 Quizs</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Certificate{" "}
                    <span className="float-end">{couserDetailData?.user}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Pass Percentage <span className="float-end">94.78%</span>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>

          {couserDetailData?.resouces && (
            <Row className="mt-5 mx-0">
              <Col xs={12} md={8} className="interview">
                <h2 className="blue f-20 fw-700 text-capitalize">
                  Interview Prep
                </h2>
                <DynamicAccordionFromHtml2 htmlContent={couserDetailData.resouces} />
              </Col>
            </Row>
          )}

          {couserDetailData?.faqs && (
            <Row className="mt-5 justify-content-center">
              <Col xs={12} md={12} lg={10}>
                <h2 className="blue pb-2">FAQ</h2>
                <DynamicAccordionFromHtml htmlContent={couserDetailData.faqs} />
              </Col>
            </Row>
          )}

        </Container>

        <div className="related">
          <Container className="py-5">
            <h2 className="blue f-20 fw-700 text-uppercase">Related Courses</h2>
            <ul className="d-flex text-start productList">
                {relatedCourse?.map((trandingCourse) => {
                  return (
                    <li className="flex-fill">
                      <div className="imgs">
                        <img
                          src={PICURL + trandingCourse?.picture}
                          style={{ maxWidth: "100%" }}
                          alt=""
                        />
                        <span className="tag">Courses</span>
                      </div>
                      <h4>{trandingCourse?.group_name} </h4>
                      <p className="author">2,486,255 Viewers</p>
                      <p className="ratings">
                        {trandingCourse?.rating}
                        <span className="stars">
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <span>({trandingCourse?.total_review} Reviews)</span>
                        </span>
                      </p>
                      <Row className="align-items-center">
                        <Col>
                          <p className="price d-inline-block">
                            {currencySymbols[trandingCourse?.price_currency] +
                              trandingCourse?.price}
                          </p>
                        </Col>

                      </Row>
                    </li>
                  );
                })}

            </ul>
          </Container>
        </div>

        <div classNavme="BetList">
          <Footer />
        </div>
      </Container>
    </>
  );
}

export default CourseDetail;
