import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { Accordion } from 'react-bootstrap'; 
export const PICURL = "https://training.uplatz.com/";
export const VIDEOURL = "https://training.uplatz.com/premium_video/";

export const stripHtml = (html) => {
  // Create a new DOM element
  const tempDiv = document.createElement("div");
  // Set the HTML content
  tempDiv.innerHTML = html;
  // Extract and return the text content
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const currencySymbols = {
  AED: "د.إ",  // United Arab Emirates Dirham
  AFN: "؋",    // Afghan Afghani
  ALL: "L",    // Albanian Lek
  AMD: "֏",    // Armenian Dram
  ANG: "ƒ",    // Netherlands Antillean Guilder
  AOA: "Kz",   // Angolan Kwanza
  ARS: "$",    // Argentine Peso
  AUD: "A$",   // Australian Dollar
  AWG: "ƒ",    // Aruban Florin
  AZN: "₼",    // Azerbaijani Manat
  BAM: "KM",   // Bosnia-Herzegovina Convertible Mark
  BBD: "$",    // Barbadian Dollar
  BDT: "৳",    // Bangladeshi Taka
  BGN: "лв",   // Bulgarian Lev
  BHD: ".د.ب", // Bahraini Dinar
  BIF: "FBu",  // Burundian Franc
  BMD: "$",    // Bermudian Dollar
  BND: "$",    // Brunei Dollar
  BOB: "Bs.",  // Bolivian Boliviano
  BRL: "R$",   // Brazilian Real
  BSD: "$",    // Bahamian Dollar
  BTN: "Nu.",  // Bhutanese Ngultrum
  BWP: "P",    // Botswana Pula
  BYN: "Br",   // Belarusian Ruble
  BZD: "BZ$",  // Belize Dollar
  CAD: "C$",   // Canadian Dollar
  CDF: "FC",   // Congolese Franc
  CHF: "CHF",  // Swiss Franc
  CLP: "$",    // Chilean Peso
  CNY: "¥",    // Chinese Yuan
  COP: "$",    // Colombian Peso
  CRC: "₡",    // Costa Rican Colón
  CUP: "$",    // Cuban Peso
  CVE: "$",    // Cape Verdean Escudo
  CZK: "Kč",   // Czech Koruna
  DJF: "Fdj",  // Djiboutian Franc
  DKK: "kr",   // Danish Krone
  DOP: "$",    // Dominican Peso
  DZD: "د.ج",  // Algerian Dinar
  EGP: "£",    // Egyptian Pound
  ERN: "Nfk",  // Eritrean Nakfa
  ETB: "Br",   // Ethiopian Birr
  EUR: "€",    // Euro
  FJD: "$",    // Fijian Dollar
  FKP: "£",    // Falkland Islands Pound
  FOK: "kr",   // Faroese Króna
  GBP: "£",    // British Pound Sterling
  GEL: "₾",    // Georgian Lari
  GGP: "£",    // Guernsey Pound
  GHS: "₵",    // Ghanaian Cedi
  GIP: "£",    // Gibraltar Pound
  GMD: "D",    // Gambian Dalasi
  GNF: "FG",   // Guinean Franc
  GTQ: "Q",    // Guatemalan Quetzal
  GYD: "$",    // Guyanese Dollar
  HKD: "HK$",  // Hong Kong Dollar
  HNL: "L",    // Honduran Lempira
  HRK: "kn",   // Croatian Kuna
  HTG: "G",    // Haitian Gourde
  HUF: "Ft",   // Hungarian Forint
  IDR: "Rp",   // Indonesian Rupiah
  ILS: "₪",    // Israeli New Shekel
  IMP: "£",    // Isle of Man Pound
  INR: "₹",    // Indian Rupee
  IQD: "ع.د",  // Iraqi Dinar
  IRR: "﷼",    // Iranian Rial
  ISK: "kr",   // Icelandic Króna
  JEP: "£",    // Jersey Pound
  JMD: "$",    // Jamaican Dollar
  JOD: "د.ا",  // Jordanian Dinar
  JPY: "¥",    // Japanese Yen
  KES: "KSh",  // Kenyan Shilling
  KGS: "с",    // Kyrgyzstani Som
  KHR: "៛",    // Cambodian Riel
  KID: "$",    // Kiribati Dollar
  KMF: "CF",   // Comorian Franc
  KRW: "₩",    // South Korean Won
  KWD: "د.ك",  // Kuwaiti Dinar
  KYD: "$",    // Cayman Islands Dollar
  KZT: "₸",    // Kazakhstani Tenge
  LAK: "₭",    // Lao Kip
  LBP: "ل.ل",  // Lebanese Pound
  LKR: "Rs",   // Sri Lankan Rupee
  LRD: "$",    // Liberian Dollar
  LSL: "L",    // Lesotho Loti
  LYD: "ل.د",  // Libyan Dinar
  MAD: "د.م.", // Moroccan Dirham
  MDL: "L",    // Moldovan Leu
  MGA: "Ar",   // Malagasy Ariary
  MKD: "ден",  // Macedonian Denar
  MMK: "K",    // Myanmar Kyat
  MNT: "₮",    // Mongolian Tögrög
  MOP: "MOP$", // Macanese Pataca
  MRU: "UM",   // Mauritanian Ouguiya
  MUR: "₨",    // Mauritian Rupee
  MVR: "Rf",   // Maldivian Rufiyaa
  MWK: "MK",   // Malawian Kwacha
  MXN: "$",    // Mexican Peso
  MYR: "RM",   // Malaysian Ringgit
  MZN: "MT",   // Mozambican Metical
  NAD: "$",    // Namibian Dollar
  NGN: "₦",    // Nigerian Naira
  NIO: "C$",   // Nicaraguan Córdoba
  NOK: "kr",   // Norwegian Krone
  NPR: "₨",    // Nepalese Rupee
  NZD: "$",    // New Zealand Dollar
  OMR: "ر.ع.", // Omani Rial
  PAB: "B/.",  // Panamanian Balboa
  PEN: "S/",   // Peruvian Sol
  PGK: "K",    // Papua New Guinean Kina
  PHP: "₱",    // Philippine Peso
  PKR: "₨",    // Pakistani Rupee
  PLN: "zł",   // Polish Zloty
  PYG: "₲",    // Paraguayan Guarani
  QAR: "ر.ق",  // Qatari Riyal
  RON: "lei",  // Romanian Leu
  RSD: "дин",  // Serbian Dinar
  RUB: "₽",    // Russian Ruble
  RWF: "FRw",  // Rwandan Franc
  SAR: "﷼",    // Saudi Riyal
  SBD: "$",    // Solomon Islands Dollar
  SCR: "₨",    // Seychellois Rupee
  SDG: "£",    // Sudanese Pound
  SEK: "kr",   // Swedish Krona
  SGD: "S$",   // Singapore Dollar
  SHP: "£",    // Saint Helena Pound
  SLL: "Le",   // Sierra Leonean Leone
  SOS: "Sh",   // Somali Shilling
  SRD: "$",    // Surinamese Dollar
  SSP: "£",    // South Sudanese Pound
  STN: "Db",   // São Tomé and Príncipe Dobra
  SYP: "£",    // Syrian Pound
  SZL: "L",    // Swazi Lilangeni
  THB: "฿",    // Thai Baht
  TJS: "ЅМ",   // Tajikistani Somoni
  TMT: "T",    // Turkmenistani Manat
  TND: "د.ت",  // Tunisian Dinar
  TOP: "T$",   // Tongan Paʻanga
  TRY: "₺",    // Turkish Lira
  TTD: "$",    // Trinidad and Tobago Dollar
  TVD: "$",    // Tuvaluan Dollar
  TWD: "NT$",  // New Taiwan Dollar
  TZS: "Sh",   // Tanzanian Shilling
  UAH: "₴",    // Ukrainian Hryvnia
  UGX: "USh",  // Ugandan Shilling
  USD: "$",    // United States Dollar
  UYU: "$",    // Uruguayan Peso
  UZS: "so'm", // Uzbekistani Som
  VES: "Bs.",  // Venezuelan Bolívar
  VND: "₫",    // Vietnamese Đồng
  VUV: "Vt",   // Vanuatu Vatu
  WST: "T",    // Samoan Tala
  XAF: "FCFA", // Central African CFA Franc
  XCD: "$",    // East Caribbean Dollar
  XOF: "CFA",  // West African CFA Franc
  XPF: "₣",    // CFP Franc
  YER: "﷼",    // Yemeni Rial
  ZAR: "R",    // South African Rand
  ZMW: "ZK",   // Zambian Kwacha
  ZWL: "$",    // Zimbabwean Dollar
};

export const ContentDisplay = ({ content }) => {
  const sanitizedContent = DOMPurify.sanitize(content); // Sanitize the content
  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
};

export const DynamicAccordionFromHtml = ({ htmlContent }) => {
  // Function to process and extract accordion items dynamically
  const processHtmlToAccordion = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html'); // Parse the HTML content into a DOM object
    const paragraphs = Array.from(doc.body.querySelectorAll('p')); // Get all <p> tags

    let accordionItems = [];
    let currentHeader = null;
    let currentBody = [];

    paragraphs.forEach((p) => {
      const isHeader = p.querySelector('strong'); // Check if <strong> tag exists inside <p>

      if (isHeader) {
        // If we have a current header and body, push the item to the accordion array
        if (currentHeader && currentBody.length > 0) {
          accordionItems.push({ header: currentHeader, body: currentBody });
          currentBody = []; // Reset body for the next item
        }
        // Set the new header (text inside <strong>)
        currentHeader = p.innerText;
      } else {
        // Add the content to the current body
        currentBody.push(p.innerHTML);
      }
    });

    // Push the last item after the loop
    if (currentHeader && currentBody.length > 0) {
      accordionItems.push({ header: currentHeader, body: currentBody });
    }

    return accordionItems;
  };

  const accordionItems = processHtmlToAccordion(htmlContent);
  return (
    <Accordion>
      {accordionItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            {item.body.map((content, i) => (
              <div key={i}><ContentDisplay content={content} /></div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export const DynamicAccordionFromHtml2 = ({ htmlContent }) => {
  // Function to process and extract accordion items dynamically
  const processHtmlToAccordion = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html'); // Parse the HTML content into a DOM object
    const elements = Array.from(doc.body.childNodes); // Get all child nodes

    let accordionItems = [];
    let currentHeader = null;
    let currentBody = [];

    elements.forEach((element) => {
      if (element.nodeType === 1 && element.tagName === 'B') {
        // If we already have a header and body, push it to accordionItems
        if (currentHeader && currentBody.length > 0) {
          accordionItems.push({ header: currentHeader, body: currentBody });
          currentBody = []; // Reset the body for the next accordion item
        }
        // Set the new header
        currentHeader = element.innerText;
      } else if (element.nodeType === 1 || element.nodeType === 3) {
        // Add the content to the current body (handling both elements and text nodes)
        currentBody.push(element.outerHTML || element.textContent);
      }
    });

    // Push the last item after the loop
    if (currentHeader && currentBody.length > 0) {
      accordionItems.push({ header: currentHeader, body: currentBody });
    }

    return accordionItems;
  };

  const accordionItems = processHtmlToAccordion(htmlContent);
  return (
    <Accordion>
      {accordionItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            {item.body.map((content, i) => (
              <div key={i}><ContentDisplay content={content} /></div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const sortList = {
  "Sort A-Z": "Sort A-Z",
  "Sort Z-A": "Sort Z-A",
  "Number of Enrolments": "Number of Enrolments",
  "Discount": "Discount",
  "Rating": "Rating",
  "Price : Low-to-High": "Price : Low-to-High",
  "Price : High-to-Low": "Price : High-to-Low"
};

/**
 * Function to convert currency from one type to another using local JSON files.
 * @param {string} currencyFrom - The original currency code (e.g., 'usd').
 * @param {number} currencyInput - The amount to be converted.
 * @return {Promise<string>} - The converted currency value as a string.
 */
export const currencyFromTo = async (currencyFrom, currencyInput) => {
  try {
      // Check if currency is already set in sessionStorage (fallback to INR)
      let currencyTo = localStorage.getItem('currency_code') || 'INR';
      currencyTo = currencyTo.toLowerCase();
      currencyFrom = currencyFrom.toLowerCase();

      // If the currencies are the same, return the amount
      if (currencyFrom === currencyTo) {
          return currencyTo.toUpperCase()+" "+Math.ceil(currencyInput);
      }

      // Build the file path based on the currency_from value
      const file = `${currencyFrom}_currency.json`;
      const url = `/currency_rate/${file}`;  // This assumes files are in the public/currency_rate folder

      // Use fetch to get the file contents
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error('Could not fetch currency data');
      }

      // Parse the JSON file
      const currencyData = await response.json();

      // Calculate the converted amount if currency data is found
      if (currencyData[currencyTo]) {
          const convertedAmount = currencyData[currencyTo] * currencyInput;
          return currencyTo.toUpperCase()+` `+Math.ceil(convertedAmount);
      }

      // If no conversion rate is found, return the input currency and amount
      return currencyFrom.toUpperCase()+` `+Math.ceil(currencyInput);
  } catch (error) {
      console.error('Error fetching currency conversion data:', error);
      // On error, return the original currency and input amount
      return currencyFrom.toUpperCase()+` `+Math.ceil(currencyInput);
  }
}

export const convertCurrency = (fromCurrency,amount) => {

  const toCurrency = localStorage.getItem('currency-code');
  const rateData = localStorage.getItem('currency_converted_rate');
  const currencyData = rateData ? JSON.parse(rateData) : null;
  // console.log("currencyData ============== ",currencyData)
  // console.log("fromCurrency ============== ",fromCurrency)
  // console.log("toCurrency ============== ",toCurrency)
  if(toCurrency != null){
    const fromRate = currencyData[fromCurrency.toLowerCase()];
    const toRate = currencyData[toCurrency.toLowerCase()];
    
    if (fromRate && toRate) {
      // Convert using the relative rates formula
      const convertedAmount = (amount / fromRate) * toRate;
      return (convertedAmount.toFixed(2)); // Round to 2 decimal places
    }
  } else {
    return amount;
  }
}

export const currencySymbolConvert = (symbol) => {
  const symbolCurrency = localStorage.getItem('currency-code');
  return (symbolCurrency) ? symbolCurrency : symbol;
}