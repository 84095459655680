import { render, screen } from "@testing-library/react";
import "../App.css";
import Header from "../component/header";
import Footer from "../component/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col, Table } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import development from "../img/testing.png";
import dataAnalysis from "../img/data-analysis.png";
import AI from "../img/ai.png";
import businees from "../img/analysis.png";
import AS from "../img/autonomous-vehicle.png";
import PM from "../img/project-management.png";
import CC from "../img/server.png";
import learner from "../img/img-leaners.png";
import courseImg from "../img/course-img.jpg";
import rightImage from "../img/RightImg.png";
import motivated from "../img/motivated.png";
import cloud from "../img/cloud.png";
import skill from "../img/skill.png";
import upskilImg from "../img/upskill-img.png";
import certification from "../img/certification.png";
import happyLearner from "../img/happy-learner.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Conected from "../img/online-classes.png";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import useApi from "../Hooks/useApi";
import React, { useEffect, useState } from "react";
import { product } from "./productjson";
import { Rating } from "react-simple-star-rating";
import { chunkArray, currencySymbols, PICURL ,convertCurrency ,currencySymbolConvert } from "../utils/helper";
import ReadMore from '../utils/ReadMore';
import { Link,useNavigate} from "react-router-dom";
import LoaderComponent from "../component/loader";

function Home() {
  let navigate = useNavigate();
  const [homeData, setHomeData] = useState([]);
  
  const [trendingData, setTrendingData] = useState([]);
  const [bundalNCareerData, setBundalNCareerData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  
  const [activeKey, setActiveKey] = useState("");
  const [currentCategory, setCurrentCategory] = useState(null);

  const {
    data: homeDataResponse,
    error: homeDataError,
    loading: homeDataLoading,
    makeApiCall: fetchHomeData,
  } = useApi("home-popular-courses", "POST", []);

  const {
    data: trendingDataResponse,
    error: trendingDataError,
    loading: trendingDataLoading,
    makeApiCall: fetchTrendingData,
  } = useApi("home-trending-and-job-oriented-courses", "GET", []);
  const {
    data: bundalNCareerDataResponse,
    error: bundalNCareerDataError,
    loading: bundalNCareerDataLoading,
    makeApiCall: fetchBundalNCareerDatagData,
  } = useApi("home-bundle-n-creerpath-courses", "GET", []);

  const {
    data: categoryDataResponse,
    error: categoryDataError,
    loading: categoryDataLoading,
    makeApiCall: fetchCategory,
  } = useApi("home-popular-course-categories", "GET", []);

  useEffect(() => {
    fetchHomeData();
    fetchTrendingData();
    fetchBundalNCareerDatagData();
    fetchCategory();
  }, []);

  useEffect(() => {
    const payload = {
      category_id: currentCategory
    };
    fetchHomeData(payload);
    setActiveKey(activeKey);
  }, [currentCategory]);

  useEffect(() => {
    if (homeDataResponse) {
      setHomeData(homeDataResponse?.data);
    }
    if (trendingDataResponse) {
      setTrendingData(trendingDataResponse?.data);
    }
    if (bundalNCareerDataResponse) {
      setBundalNCareerData(bundalNCareerDataResponse?.data);
    }
    if (categoryDataResponse) {
      setCategoryData(categoryDataResponse?.data);
      if(!activeKey){
        setActiveKey(categoryDataResponse?.data[0]?.category_name);
        setCurrentCategory(categoryDataResponse?.data[0]?.id)
      }
    }
  }, [
    homeDataResponse,
    trendingDataResponse,
    homeDataError,
    trendingDataError,
    homeDataLoading,
    trendingDataLoading,
    bundalNCareerDataResponse,
    bundalNCareerDataLoading,
    bundalNCareerDataError,
    categoryDataResponse,
  ]);

  const chunkedData = chunkArray(bundalNCareerData, 5);
  const useData = localStorage.getItem('user-data');
  const user = useData ? JSON.parse(useData) : null;
  const currencyData = localStorage.getItem('currency-code');
  const currencyCode = currencyData ? currencyData : "INR"
  const handleCourseDetail = (course) => {
    navigate("/course-detail", {
      state: {
        course_id: course?.id,
      },
    });
  };



  // const handleFetchCurrency = async (price) => {
  //   try {
  //       const currencyInfo = await currencyFromTo(currencyCode,price);
  //       console.log("currencyInfo: ", currencyInfo);
  //       return currencyInfo;
  //       // You can do something with currencyInfo here, like showing an alert or logging
  //   } catch (error) {
  //       console.error("Error fetching currency info: ", error);
  //   }
  // };

  //handleFetchCurrency(100)

  return (
    <>
      <Container fluid>
        <div className="BetList">
          <Header />
        </div>
        <div className="achiveBox py-5">
          <h2 className="mt-5">Achieve your career goals with Uplatz</h2>
          <p>
            Get access to 7,000+ courses, hands-on projects, and certificate
            programs from Google, <br />
            Meta, Duke and more with a Uplatz subscription.
          </p>

          <Row>
            <Col className="my-3">
              <Link to="/online-course">
                <Button
                  className="me-2 rounded-1 btn-red"
                  variant="primary"
                  size="lg"
                >
                  Start Learning
                </Button>
              </Link>
              <Link to="/online-course">
                <Button
                  className="rounded-1 btn-outline-light bg-transparent"
                  variant="secondary"
                  size="lg"
                >
                  Browse all course
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col className="my-4">
              <div className=" icnBlock">
                <span className="iconImg">
                  <img src={dataAnalysis} style={{ width: "55px" }} />
                </span>
                <p className="icnText">Data Science</p>
              </div>
              <div className=" icnBlock">
                <span className="iconImg">
                  <img src={development} style={{ width: "55px" }} />
                </span>
                <p className="icnText">Programming & Development</p>
              </div>
              <div className=" icnBlock">
                <span className="iconImg">
                  <img src={AI} style={{ width: "55px" }} />
                </span>
                <p className="icnText">Artificial Inteligence</p>
              </div>
              <div className=" icnBlock">
                <span className="iconImg">
                  <img src={businees} style={{ width: "55px" }} />
                </span>
                <p className="icnText">Business</p>
              </div>
              <div className=" icnBlock">
                <span className="iconImg">
                  <img src={AS} style={{ width: "55px" }} />
                </span>
                <p className="icnText">Autonomous Systems</p>
              </div>
              <div className=" icnBlock">
                <span className="iconImg">
                  <img src={CC} style={{ width: "55px" }} />
                </span>
                <p className="icnText">Product Management</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="learners py-5 text-start">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6} lg={6}>
                <p className="mb-0">Leader’s in Online Learning</p>
                <h2>
                  Achieve your career goals with <span>Uplatz</span>
                </h2>
                <p>
                  Stand out to employers with an industry-leading Professional
                  Certificate and must-have AI skills from Google, IBM, Meta,
                  Microsoft, and more.
                </p>
                <Button
                  className="me-2 rounded-1 btn-red"
                  variant="primary"
                  size="lg"
                >
                  Explore Certification
                </Button>
                <Link to="/signup">
                <Button
                  className="rounded-1 btn-blue"
                  variant="secondary"
                  size="lg"
                >
                  Join Free
                </Button>
                </Link>
                <Row className="mt-4">
                  <Col
                    xs={12}
                    md={4}
                    className="text-center text-md-start mb-3 mb-md-0"
                  >
                    <h3>10 Million+</h3>
                    <p className="smText">Learners Have Trusted Us</p>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className="text-center text-md-start mb-3 mb-md-0"
                  >
                    <h3>500+</h3>
                    <p className="smText">Courses</p>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className="text-center text-md-start mb-3 mb-md-0"
                  >
                    <h3>50+</h3>
                    <p className="smText">Specialisations</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} lg={6} className="text-center">
                <img src={learner} style={{ maxWidth: "90%" }} />
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="PCourses py-5">
          <h2 className="mt-4">Popular Courses</h2>
          <LoaderComponent loader={homeDataLoading} />
          <Tabs
              activeKey={activeKey}
              onSelect={(k) => {
                setActiveKey(k);
                const selectedCategory = categoryData.find(category => category.category_name === k);
                if(selectedCategory){
                  setCurrentCategory(selectedCategory.category_id)
                }
              }}
              id="controlled-tab-example"
              className="justify-content-center mt-3 text-start"
            >
              {categoryData?.map((category) => (
                <Tab
                  eventKey={category?.category_name}
                  title={category?.category_name}
                  key={category?.category_id} // Unique key for each Tab
                  
                >
                  <ul className="d-flex text-start">
                    {homeData?.map((trandingCourse) => (
                      <li
                        className="flex-fill cursor-pointer"
                        onClick={() => handleCourseDetail(trandingCourse)}
                        key={trandingCourse?.id} // Unique key for each list item
                      >
                        <div className="imgs">
                          <img
                            src={PICURL + trandingCourse?.picture}
                            style={{ maxWidth: "100%" }}
                            alt=""
                          />
                          <span className="tag">Courses</span>
                        </div>
                        <h4>{trandingCourse?.group_name}</h4>
                        <p className="author">2,486,255 Viewers</p>
                        <p className="ratings">
                      {trandingCourse?.rating}
                      <span className="stars">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <span>({trandingCourse?.total_review} Reviews)</span>
                      </span>
                    </p>
                      <Row className="align-items-center">
                      <Col>
                        <p className="price d-inline-block">
                        {currencySymbolConvert(trandingCourse?.price_currency)}  {convertCurrency(trandingCourse?.price_currency,trandingCourse?.price)}
                        </p>
                      </Col>
                      </Row>                       
                      </li>
                    ))}
                  </ul>
                </Tab>
              ))}
            </Tabs>
        </Container>

        <div className="GreyBG py-5 courseBlock">
          <Container>
            <h2>Trending Courses</h2>
            <ul className="d-flex text-start productList mb-5">
              {trendingData?.Trendingcourses?.map((trandingCourse) => {
                return (
                  <li className="flex-fill cursor-pointer" key={trandingCourse?.id} onClick={() => handleCourseDetail(trandingCourse)}>
                    <div className="imgs">
                      <img
                        src={PICURL + trandingCourse?.picture}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      <span className="tag">Courses</span>
                    </div>
                    <h4>{trandingCourse?.group_name} </h4>
                    <p className="author">2,486,255 Viewers</p>
                    <p className="ratings">
                      {trandingCourse?.rating}
                      <span className="stars">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <span>({trandingCourse?.total_review} Reviews)</span>
                      </span>
                    </p>
                    <Row className="align-items-center">
                      <Col>
                        <p className="price d-inline-block">
                          {currencySymbolConvert(trandingCourse?.price_currency)}  {convertCurrency(trandingCourse?.price_currency,trandingCourse?.price)}
                        </p>
                      </Col>
                      <Col xs={7}>
                        {useData ? <></> : <><Link to="/login"><Button className="me-2 entollNow" variant="primary">Enroll Now</Button></Link></>}
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ul>

            <h2>Top Job oriented courses</h2>
            <ul className="d-flex text-start productList">
              {trendingData?.JobOreinetdcourses?.map((trandingCourse) => {
                return (
                  <li className="flex-fill" key={trandingCourse?.id} onClick={() => handleCourseDetail(trandingCourse)}>
                    <div className="imgs">
                      <img
                        src={PICURL + trandingCourse?.picture}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      <span className="tag">Courses</span>
                    </div>
                    <h4>{trandingCourse?.group_name} </h4>
                    <p className="author">2,486,255 Viewers</p>
                    <p className="ratings">
                      {trandingCourse?.rating}
                      <span className="stars">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <span>({trandingCourse?.total_review} Reviews)</span>
                      </span>
                    </p>
                    <Row className="align-items-center">
                      <Col>
                        <p className="price d-inline-block">
                          {currencySymbolConvert(trandingCourse?.price_currency)}  {convertCurrency(trandingCourse?.price_currency,trandingCourse?.price)}
                        </p>
                      </Col>
                      <Col xs={7}>
                        {useData ? <></> : <><Link to="/login"><Button className="me-2 entollNow" variant="primary">Enroll Now</Button></Link></>}
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ul>
          </Container>
        </div>

        <div className="careerPath py-5">
          <Container className="py-5">
            <h2>Save on Bundles and Career Path Courses</h2>
            <Carousel interval={1500}>
              {chunkedData.map((chunk, index) => (
                <Carousel.Item key={index}>
                  <ul className="d-flex text-start">
                    {chunk.map((careerPath) => (
                      <li key={careerPath.id} className="flex-fill cursor-pointer" onClick={() => handleCourseDetail(careerPath)}>
                        <div className="imgs">
                          <img
                            src={PICURL + careerPath?.picture}
                            style={{ maxWidth: "100%" }}
                            alt={careerPath.group_name}
                          />
                          <span className="tag">Courses</span>
                        </div>
                        <h4>{careerPath.group_name}</h4>
                        <p>
                          Include <b>{careerPath.duration}</b> Courses
                        </p>
                        <p>
                          <b>{currencySymbolConvert(careerPath?.price_currency)}  {convertCurrency(careerPath?.price_currency,careerPath?.price)} </b>
                           Included
                        </p>
                      </li>
                    ))}
                  </ul>
                </Carousel.Item>
              ))}
            </Carousel>
          </Container>
        </div>

        <div className="blueBG py-5">
          <Container>
            <Row className="align-items-center py-5">
              <Col className="outCome" xs={12} md={6}>
                <h2>Learner outcomes on Uplatz</h2>
                <p>
                  With these programs, you can build valuable skills, earn
                  career credentials, and make progress toward a degree before
                  you even enroll.
                </p>
                <Row className="mb-4">
                  <Col xs={12} md={6} className="mb-4 mb-md-0">
                    <div className="bBox">
                      <img src={motivated} />
                      <p>Content Stay motivated with engaging instructors</p>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="bBox">
                      <img src={cloud} />
                      <p>Keep up with in the latest in cloud</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mb-4 mb-md-0">
                    <div className="bBox">
                      <img src={certification} />
                      <p>Get certified with 100+ certification courses</p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-4 mb-md-0">
                    <div className="bBox">
                      <img src={skill} />
                      <p>Build skills your way, from labs to courses</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <img src={rightImage} style={{ maxWidth: "100%" }} />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="StayUpdate py-5">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <img src={Conected} style={{ maxWidth: "100%" }} />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <h2 className="blue mt-5">Start Learning Now</h2>
                <p className="f-12">
                  Enter your email address to receive 20% discount on your first
                  course
                </p>

                <Form.Control
                  className="f-12 semi-grey w-70"
                  placeholder="Enter Email Address"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <Button
                  className="me-2 rounded-1 btn-red d-inline-block w-40 mx-2 text-uppercase mt-2 mt-md-0"
                  variant="primary"
                >
                  Subscribe Now
                </Button>

                <Row className="justify-content-md-center mt-4">
                  <Col md={10} lg={12} className="mb-4 mt-4">
                    <Link to="/online-course">
                    <Button
                      className="me-2 rounded-1 btn-learn"
                      variant="primary"
                      size="lg"
                    >
                      Start Learning
                    </Button>
                    </Link>
                    <Link to="/online-course">
                    <Button
                      className="rounded-1 brwse-course"
                      variant="secondary"
                      size="lg"
                    >
                      Browse all course
                    </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="learnzer py-5">
        <Container className="text-center">
          <h2 className="blue mt-5 mb-3">Learners love Uplatz</h2>
          <Row className="mb-4">
            <Col>
              <div className="testiBlock">
                <ReadMore text="I took comprehensive training on SAP FICO from Uplatz - I am more than 100% satisfied as the tutor assigned for the training explained the concepts well, got me on to the practice server from the first session itself, cleared all my doubts whenever I asked him or emailed him, gave me the complete recordings of all the sessions, high-quality study material developed on his own, etc. And all this at an amazing price of just £300. Awesome! and thanks. I would rate this as my best learning experience ever." />
                <h4>Naresh Sharma</h4>
              </div>
            </Col>
            <Col>
              <div className="testiBlock">
                <ReadMore text="Though I am myself part of Uplatz team albeit as a Mentor so my comments may be a bit biased but yea this is one company I think which is actually providing some 'real value'. There are a couple of learning portals in the market but I guess what distinguishes Uplatz.com is the fact that you guys don't just boast about yourself or run after money, but are providing valuable connectivity between the tutors and the knowledge hungry learners. The course content and delivery is excellent, and the tutors quality is superb. Real leads, real courses, real skill-gap bridge, real knowledge, real value for money - Bang!" />
                <h4>Bhaskar Khemchandani</h4>
              </div>
            </Col>
          </Row>
        </Container>
        </div>

        <div className="happyLearner py-5">
          <Container className="py-5">
            <Row className="align-items-center">
              <Col className="text-center mb-3 mb-md-0" xs={12} md={6}>
                <h2>
                  Are you ready to start <br />
                  your learning?
                </h2>

                <div className="d-grid gap-2 justify-content-center">
                  {useData ? <></> : <><Link to="/login" style={{textDecoration:"none"}}><Button className="me-2 rounded-1 btn-learn" variant="primary" size="lg" >Enrol Now</Button></Link></>}
                  <Link to="/online-course" style={{textDecoration:"none"}}>
                    <Button
                      className="rounded-1 brwse-course"
                      variant="secondary"
                      size="lg"
                    >
                      Browse all course
                    </Button>
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <img src={happyLearner} style={{ maxWidth: "100%" }} />
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="upskills">
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <img src={upskilImg} style={{ maxWidth: "100%" }} />
            </Col>
            <Col xs={12} md={6}>
              <h2 className="blue">Want your team to upskill?</h2>
              <h4 className="mb-4">
                Scale skills development for your organization
              </h4>
              <ul>
                <li>Hands-on project-based approach</li>
                <li>Top content co-developed with industry leaders</li>
                <li>Global mentor network with 700+ domain experts</li>
              </ul>
              <Link to="/online-course">
              <Button
                className="me-2 rounded-1 btn-learn"
                variant="primary"
                size="lg"
              >
                Start Learning
              </Button>
              </Link>
              <Link to="/online-course">
              <Button
                className="rounded-1 brwse-course"
                variant="secondary"
                size="lg"
              >
                Browse all course
              </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="BetList">
          <Footer />
        </div>
      </Container>
    </>
  );
}

export default Home;
