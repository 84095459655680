import React from "react";
import "./loader.css";

const LoaderComponent = ({ loader }) => {
  return (
    <>
      {loader && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">loading...</div>
        </div>
      )}
    </>
  );
};

export default LoaderComponent;
