import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

import facebook from '../img/facebook.png';
import twitter from '../img/twitter.png';
import linkedin from '../img/linked-in.png';
import pintrest from '../img/pintrest.png';
import insta from '../img/instagram.png';

import props from 'prop-types';
function Footer() {

    return (
        <div className="footer">
             <div className='FootTop'>
            <Container>
               
                <Row>
                    <Col  xs={12} md={6}  lg={3}  className='mb-2 mb-lg-0'>
                        <h3>Company</h3>
                        <ListGroup>
                            <ListGroup.Item>About Us</ListGroup.Item>
                            <ListGroup.Item>Why Uplatz?</ListGroup.Item>
                            <ListGroup.Item>Blog</ListGroup.Item>
                            <ListGroup.Item>In the News</ListGroup.Item>
                            <ListGroup.Item>Jobs at Uplatz</ListGroup.Item>
                            <ListGroup.Item>Become a Mentor</ListGroup.Item>
                            <ListGroup.Item>Partner with Uplatz</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xs={12} md={6}  lg={3}  className='mb-2 mb-lg-0'>
                    <h3>Resources</h3>
                        <ListGroup>
                            <ListGroup.Item>Articles</ListGroup.Item>
                            <ListGroup.Item>Blog</ListGroup.Item>
                            <ListGroup.Item>Cheatsheets</ListGroup.Item>
                            <ListGroup.Item>Code challenges</ListGroup.Item>
                            <ListGroup.Item>Docs</ListGroup.Item>
                            <ListGroup.Item>Projects</ListGroup.Item>
                            <ListGroup.Item>Videos</ListGroup.Item>
                            <ListGroup.Item>Workspaces</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xs={12} md={6}  lg={3}  className='mb-2 mb-lg-0'>
                    <h3>Community</h3>
                        <ListGroup>
                            <ListGroup.Item>Clubs</ListGroup.Item>
                            <ListGroup.Item>Code Crew</ListGroup.Item>
                            <ListGroup.Item>Discord</ListGroup.Item>
                            <ListGroup.Item>Events</ListGroup.Item>
                            <ListGroup.Item>Forums</ListGroup.Item>
                            <ListGroup.Item>Learner Stories</ListGroup.Item>
                            <ListGroup.Item>Catalog</ListGroup.Item>
                            <ListGroup.Item>Help and FAQ</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xs={12} md={6} lg={3} className='mb-2 mb-lg-0'>
                    <h3>Only at Uplatz</h3>
                        <ListGroup>
                            <ListGroup.Item>Artificial Intelligence</ListGroup.Item>
                            <ListGroup.Item>Deep Learning</ListGroup.Item>
                            <ListGroup.Item>Digital Marketing</ListGroup.Item>
                            <ListGroup.Item>Flying Car and Autonomous Flight Engineer</ListGroup.Item>
                            <ListGroup.Item>Intro to Self-Driving Cars</ListGroup.Item>
                            <ListGroup.Item>Machine Learning Engineer</ListGroup.Item>
                            <ListGroup.Item>Robotics Software Engineer</ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
               
            </Container>
             </div>

             <div className='footBtm'>
                <Container>
                    <Row>
                        <Col xs={12}  md={8} className='text-center text-md-start'>© 2024 Uplatz Inc. All rights reserved.   Terms & Condition   -   Privacy Policy   -   Help</Col>
                        <Col xs={12} md={4} className='text-center text-md-end f-14'>
                             <a href='https://www.facebook.com/UplatzGlobal/' target='_blank'><img src={facebook}/></a>
                             <a href='https://twitter.com/Uplatz_Global' target='_blank'><img src={twitter}/></a>
                             <a href='https://www.linkedin.com/company/uplatz/' target='_blank'><img src={linkedin}/></a>
                             <a href='https://www.pinterest.co.uk/uplatz_global/' target='_blank'><img src={pintrest}/></a>
                             <a href='https://www.instagram.com/uplatz_global/' target='_blank'><img src={insta}/></a>
                        </Col>
                    </Row>
                </Container>
             </div>
      </div>
    );
}


export default Footer;