import { useEffect, useState } from "react";
import '../App.css';
import Header from "../component/header";
import Footer from '../component/footer';
import { Container, Row, Col, Table,Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import courseImg from '../img/course-img.jpg';
import stripeImg from '../img/stripe1.png';
import paypalImg from '../img/Paypal.png';
import payumoneyImg from '../img/payumoney.png';
import { FaTrash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import useApi from "../Hooks/useApi";
import {  PICURL, VIDEOURL ,convertCurrency ,currencySymbolConvert} from "../utils/helper";
import Swal from 'sweetalert2';
import { loadStripe } from '@stripe/stripe-js';
import LoaderComponent from "../component/loader";

const stripePromise = loadStripe('pk_test_rGHf4XOFYgWR5VKxzECTzjWk');

function MyCart() {
    
    const {
        data: cartResponse,
        error: cartError,
        loading: cartLoading,
        makeApiCall: cartData,
      } = useApi("cart", "GET", []);


      const {
        data: cartDeleteResponse,
        error: cartDeleteError,
        loading: cartDeleteLoading,
        makeApiCall: cartDeleteData,
      } = useApi("cart/remove", "post", []);

      

      const {
        data: stripeResponse,
        error: stripeError,
        loading: stripeLoading,
        makeApiCall: stripeData,
      } = useApi("stripe-payment", "post", []);


      const {
        data: paypalResponse,
        error: paypalError,
        loading: paypalLoading,
        makeApiCall: paypalData,
      } = useApi("paypal-payment", "post", []);

      const {
        data: payuResponse,
        error: payuError,
        loading: payuLoading,
        makeApiCall: payuData,
      } = useApi("payumoney-payment", "post", []);


    const [cartItems, setCartItems] = useState([]);
    const [lineItem, setLineItem] = useState([]);
    const [finalTotal, setFinalTotal] = useState(0);
    const [baseCurrency, setBaseCurrency] = useState("GBP");
    // State to track selected payment method
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stripe");

    // Calculate the total price
    const totalPrice = cartItems.reduce((acc, item) => {
        let otherData;
        try {
            // Safely parse other_data if available
            otherData = item?.other_data ? JSON.parse(item.other_data) : {};
        } catch (error) {
            console.error("Error parsing other_data", error);
            otherData = {}; // Fallback to an empty object if parsing fails
        }
        // Safely access final_price, ensure it's a number, and add to accumulator
        const finalPrice = otherData.final_price ? parseFloat(otherData.final_price) : 0;
        
        return acc + finalPrice; // Add final_price to the accumulator
    }, 0);
    console.log("Total Price:", totalPrice);

    useEffect(() => {
        let tempLineItems = [];
        cartItems.forEach(item => {
            let otherData;
            try {
                // Safely parse other_data if available
                otherData = item?.other_data ? JSON.parse(item.other_data) : {};
            } catch (error) {
                console.error("Error parsing other_data", error);
                otherData = {}; // Fallback to an empty object if parsing fails
            }
            // Collect titles for line items
            if (otherData?.title) {
                tempLineItems.push(otherData.title); // Push the title to the temp array
            }
        });
        // After processing all cart items, update the state
        setLineItem(tempLineItems); // Set the line items state
    }, [cartItems]); // Re-run the effect when cartItems changes


    useEffect(() => {
        cartData();
    }, []);

    useEffect(() => {
        if(cartResponse?.status){
            setCartItems(cartResponse?.cartItems)
        }
    });

    const handleDelete  = async (itemId) => {
        
        console.log(`Deleting item ${itemId}`);
        await cartDeleteData({cart_id:itemId})
        setTimeout(()=>{
            cartData();
        },1000);
        // Call your deleteHandle function here or any API for deletion.
    };

    const handleDeleteConfirmation = (itemId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(itemId)
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Your item has been deleted.',
                            'success'
                        );
                        setTimeout(()=>{
                            window.location.reload();
                        },1500);

                        
                    })
                    .catch((error) => {
                        Swal.fire(
                            'Error!',
                            'There was a problem deleting your item.',
                            'error'
                        );
                        console.error('Delete error:', error);
                    });
            }
        });
    }
    const handlePayment = async () => {
        console.log("Selected Payment Method:", selectedPaymentMethod);

        const currencyCode  = localStorage.getItem('currency-code');
        const currency = (currencyCode!=null)?currencyCode:"GBP";
        const totalFinalAmount = localStorage.getItem('total-final-pay')
        const stripe = await stripePromise;
        if(selectedPaymentMethod == "stripe"){
            // Send request to Laravel backend to create Stripe checkout session
            stripeData({
                final_amount: totalFinalAmount,
                currency: currency,
                success_url: window.location.origin+'/payment-confirmation',
                cancel_url: window.location.href,
                line_items:lineItem.join(" and ")
            })
        } else if (selectedPaymentMethod == "paypal") {
            paypalData({
                final_amount: totalFinalAmount,
                currency_code: currency,
                success_url: 'https://training.uplatz.com/payment-confirmation',
                cancel_url: 'https://training.uplatz.com/payment-confirmation',
                item_name: 'Uplatz Payment',
                no_shipping: 1,
                custom: "",
                handling: 0            })
        } else if (selectedPaymentMethod == "PayUMoney") {
            payuData({
                txnid: 'TXN' + new Date().getTime(),
                amount: totalFinalAmount,
                currency: currency,
                success_url: window.location.origin+'/payment-confirmation',
                cancel_url: window.location.href,
                productinfo:lineItem.join(" and ")
            })
        } else {
            stripeData({
                final_amount: totalFinalAmount,
                currency: currency,
                success_url: window.location.origin+'/payment-confirmation',
                cancel_url: window.location.href,
                line_items:lineItem.join(" and ")
            })
        }
    };

    useEffect(() => {
        const handleStripeRedirect = async () => {
            try {
                if (stripeResponse?.status) {
                    const stripe = await stripePromise;
    
                    const sessionId = stripeResponse.id;
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: sessionId,
                    });
    
                    if (error) {
                        console.error("Stripe Error:", error.message);
                    }
                }
            } catch (error) {
                console.error('Stripe payment error:', error);
            }
        };
        handleStripeRedirect();
    }, [stripeResponse]);

    useEffect(() => {
        const handlePaypalRedirect = async () => {
            try {
                if (paypalResponse?.status) {
                    if (paypalResponse.status === 'success') {
                        window.location.href = paypalResponse.links.find(link => link.rel === 'approval_url').href; // Redirect to PayPal
                    } else {
                        // Handle error scenario
                        console.error("Payment Error:", paypalResponse.message);
                    }
                }
            } catch (error) {
                console.error('Paypal payment error:', error);
            }
        };
        handlePaypalRedirect();
    }, [paypalResponse]);

    useEffect(() => {
        const handlePayuRedirect = async () => {
            try {
                if (payuResponse) {
                    const { action, params } = payuResponse;
    
                    // Create a form and submit it to PayU Money
                    const form = document.createElement('form');
                    form.action = action;
                    form.method = 'POST';
                    Object.keys(params).forEach(key => {
                        const input = document.createElement('input');
                        input.name = key;
                        input.value = params[key];
                        form.appendChild(input);
                    });
                    document.body.appendChild(form);
                    form.submit();
                }
            } catch (error) {
                console.error('PayU Money payment error:', error);
            }
        };
        handlePayuRedirect();
    }, [payuResponse]);

    return (
        <>
            <LoaderComponent loader={stripeLoading || paypalLoading || payuLoading} />
            <Container fluid>
                <div className='BetList'>
                <Header />
                </div>
                <Container className="py-5">
                    <Row>
                        <Col md={8}>
                            <h3 className="mb-4">Review Your Order</h3>
                            {cartItems && cartItems.length > 0 ? (
                                <ul className="list-unstyled cart-items">
                                    {cartItems.map(item => {
                                        let otherData;
                                        try {
                                            // Safely parse other_data if available
                                            otherData = item?.other_data ? JSON.parse(item.other_data) : {};
                                        } catch (error) {
                                            console.error("Error parsing other_data", error);
                                            otherData = {}; // Fallback to an empty object if parsing fails
                                        }
                                       
                                        return (
                                            <li key={item.id} className="border p-3 mb-3 d-flex align-items-center cart-item">
                                                <div className="cart-imgs">
                                                    <img src={PICURL + otherData?.picture} alt={otherData?.title} title={otherData?.title} />
                                                </div>

                                                <div className="cart-details">
                                                    <h5 className="cart-item-name">{otherData?.title}</h5>
                                                    <p className="cart-item-tutor">Tutor: {otherData?.u_fullname}</p>
                                                    <div className="cart-prices">

                                                        <p><strong>Price:</strong> {currencySymbolConvert(otherData.currency)} {convertCurrency(otherData.currency,otherData.price)}</p>
                                                        <p><strong>Discount:</strong> {currencySymbolConvert(otherData.currency)} {convertCurrency(otherData.currency,otherData.discount_price)}</p>
                                                        <p><strong>Subtotal:</strong> {currencySymbolConvert(otherData.currency)} {convertCurrency(otherData.currency,otherData.final_price)}</p>
                                                    </div>
                                                </div>

                                                <div className="cart-actions">
                                                    <Button variant="danger" size="sm" onClick={() => {
                                                        handleDeleteConfirmation(item.id);
                                                    }}>
                                                        <FaTrash /> Remove
                                                    </Button>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <ul className="list-unstyled cart-items">
                                    <li  className="border p-3 mb-3 d-flex align-items-center cart-item">
                                        <div className="cart-details">
                                            <h5 className="mb-4">Your cart is empty.</h5>
                                        </div>
                                    </li>
                                </ul>
                            )}

                            {/* Add More Courses to Cart */}
                            <p className="text-start">
                                <a href="/online-course" className="text-primary">Add more courses to cart</a>
                            </p>

                            {/* First Promo Line */}
                            <div className="d-flex justify-content-between align-items-center mb-3 promo-section">
                                <Form.Control type="text" placeholder="Promo code" style={{ width: '400px' }} />
                                <Button variant="primary" className="ms-2">Redeem</Button>
                                <div className="order-total ms-auto"><strong>Order Total:</strong> {currencySymbolConvert(baseCurrency)} {convertCurrency(baseCurrency,totalPrice)} </div>
                            </div>

                            {/* Second Promo Line */}
                            <div className="d-flex justify-content-between align-items-center promo-section shadow-sm">
                                <span>
                                    Available Ucash: 0 {currencySymbolConvert(baseCurrency)} <br/>
                                    (You can use max 50% of course price)
                                </span>
                                <Form.Control type="number" placeholder="Ucash to Redeem Now" style={{ width: '400px' }} />
                                <Button variant="primary" className="ms-2">Redeem</Button>
                            </div>
                        </Col>

                        <Col md={4}>
                            <h3>Pay via</h3>
                            <div className="border p-3 mb-3 payment-options">
                                <Form.Check type="radio" label="Stripe (For all currency payments using credit or debit card)" 
                                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    name="payment" value={"stripe"} defaultChecked />
                                <Form.Check type="radio" label="PayPal (If you want to use PayPal)" name="payment" 
                                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    value={"paypal"} className="mt-2" />
                                <Form.Check type="radio" label="PayU Money (For all INR payments)" name="payment" 
                                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                value={"PayUMoney"} className="mt-2" />
                            </div>
                            <div className="border p-3 total-summary">
                                <h4>Order Total: <span className="float-end">{currencySymbolConvert(baseCurrency)} {convertCurrency(baseCurrency,totalPrice)}</span>
                                {localStorage.setItem('total-final-pay',convertCurrency(baseCurrency,totalPrice))}</h4>
                                <Button disabled={totalPrice <= 0}  variant="warning" onClick={handlePayment} className="w-100 mt-3">Pay Now</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className='BetList'>
                    <Footer />
                </div>
            </Container>
        </>
    );
}

export default MyCart;