import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import '../App.css';
import Header from '../component/header';
import Footer from '../component/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col, Alert } from 'react-bootstrap';
import signImg from '../img/signup-img.png';
import gLogin from '../img/g-login.png';
import fLogin from '../img/f-login.png';
import aLogin from '../img/a-login.png';
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import useApi from '../Hooks/useApi';
import { Link,useNavigate } from "react-router-dom";

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { data, error, loading, makeApiCall } = useApi('login', 'POST', []);
    const navigate = useNavigate(); // Initialize the navigate function
    const useData = localStorage.getItem('user-data');
    useEffect(()=>{
        if(useData){
            navigate('/')
        }
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(false);
        setErrors({});

        try {
            await LoginSchema.validate({ email, password }, { abortEarly: false });
            console.log('Form submitted successfully with values:', { email, password });
            // Make the API call
            await makeApiCall({ email, password });
            // Check if the API call was successful
            setSubmitted(true);
        } catch (err) {
            const validationErrors = {};
            if (err.inner && Array.isArray(err.inner)) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
            } else {
                console.error('Unexpected validation error:', err);
            }
            setErrors(validationErrors);
        }
    };

    useEffect(() => {
        if (submitted && data) {
            console.log("API response data:", data);
            setShowSuccess(true);
            localStorage.setItem('user-data', JSON.stringify(data));
            localStorage.setItem('auth-key', data?.access_token);
            navigate('/my-course-list'); // Redirect to the homepage
        }

        if (error) {
            console.log("API error:", error.message);
            setErrors({ api: error.message });
        }
    }, [data, error, submitted, navigate]);

    useEffect(() => {
        if (showSuccess) {
            const timer = setTimeout(() => {
                setShowSuccess(false);
                setSubmitted(false); // Optionally reset the submitted state
            }, 3000); // Adjust the time (in milliseconds) as needed

            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [showSuccess]);

    return (
        <>
            <Header />
            <Container fluid>
                <Container className='py-5'>
                    <Row className='align-items-center'>
                        <Col xs={12} md={7}>
                            <img src={signImg} alt="Sign Up" style={{ maxWidth: '100%' }} />
                        </Col>
                        <Col xs={12} md={5}>
                            <h2 className='blue text-center'>Log in to continue your <br /> learning journey</h2>

                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail" className='my-3'>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email Address"
                                        className={`f-12 formInput my-3 ${errors.email ? 'is-invalid' : ''}`}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        className={`f-12 formInput my-3  ${errors.password ? 'is-invalid' : ''}`}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                </Form.Group>

                                {error && <Alert variant="danger">Error: {errors.api}</Alert>}
                                {submitted && !error && <Alert variant="success">Login successful!</Alert>}
                                {/* {loading && <Alert variant="success">Loading...</Alert>} */}
                                <Button
                                    type="submit"
                                    className='me-2 w-100 rounded-1 btn-red text-uppercase f-17 fw-700'
                                    variant="primary"
                                    size="lg"
                                    disabled={loading}  // Disable the button when loading
                                >
                                    {loading ? 'Loading...' : 'Login'}
                                </Button>
                            </Form>

                            <p className='f-12 crossLine'><span>Other log in options</span></p>
                            <p className='text-center'>
                                <img src={gLogin} alt="Google Login" className='mx-1' />
                                <img src={fLogin} alt="Facebook Login" className='mx-1' />
                                <img src={aLogin} alt="Apple Login" className='mx-1' />
                            </p>
                            <p className='f-12 text-center mt-3'>
                                Don’t have an Account? <Link to='/signup'>Sign Up</Link>
                            </p>
                            {/* <Button
                                className='rounded-1 brwse-alredy w-100'
                                variant="secondary"
                                size="lg"
                            >
                                <b>Login with your organization</b>
                            </Button> */}
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </>
    );
}

export default Login;
