import React from "react";

const PageNotFound = () => {
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f0f4f8", // Light blue background
    };

    const titleStyle = {
        fontSize: "8rem",
        color: "#007bff", // Blue color for the 404 text
        margin: 0,
    };

    const textStyle = {
        fontSize: "3.5rem",
        color: "#585253", // Red color for the text
        marginTop: "20px",
    };

    return (
        <div style={containerStyle}>
            <h2 style={titleStyle}>404</h2>
            <p style={textStyle}>Page Not Found</p>
        </div>
    );
};

export default PageNotFound;
