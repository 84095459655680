import {React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../img/uplatz-logo.png';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faGlobe ,faCartPlus , faCheck} from '@fortawesome/free-solid-svg-icons';
import {faBell, faHeart } from '@fortawesome/free-regular-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import useApi from "../Hooks/useApi";
import props from 'prop-types';


function Header() {
  const navigate = useNavigate()
  const useData = localStorage.getItem('user-data');
  const user = useData ? JSON.parse(useData) : null;


  // State to hold cart item count
  const [cartItemCount, setCartItemCount] = useState(0);


  // API hook for fetching cart item count
  const { data: cartData, error: cartError, loading: cartLoading, makeApiCall: fetchCartItemCount } = useApi(
    "get-cart-item-count",
    "GET",
    []
  );

  const { data, error, loading, makeApiCall } = useApi(
    "get-currency",
    "GET"
  );
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyCode , setCurrencyCode] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    // Make the API call
    makeApiCall();
    //fetchGetCurrencyRate();
    //console.log("getCurrencyRate : ",getCurrencyRate?.data)

    if (user) {
      // Fetch cart item count if user is logged in
      fetchCartItemCount();
    }
  }, []); // Only run once on component mount

  const { 
    data: updateCurrencyRate,
    error: updateCurrencyRateError,
    loading: updateCurrencyRateLoading,
    makeApiCall: fetchUpdateCurrencyRate,
   } = useApi(
    "update-currency",
    "POST",
    []
  );

  // const {
  //   data: getCurrencyRate,
  //   error: getCurrencyRateError,
  //   loading: getCurrencyRateLoading,
  //   makeApiCall: fetchGetCurrencyRate,
  // } = useApi("get-currency-rate", "GET", []);

  useEffect(() => {
    if(currencyCode!=null){
      fetchUpdateCurrencyRate({currency:currencyCode})
      //fetchGetCurrencyRate()
    }
  }, [currencyCode]);

  // Handle data update when `data` changes
  useEffect(() => {
    if (data) {
      setCurrencyData(data?.data);
      if(data?.currency_converted_rate){
        localStorage.setItem('currency_converted_rate',JSON.stringify(data?.currency_converted_rate));
        const currencyCode  = localStorage.getItem('currency-code');
        const latest = (currencyCode!=null)?currencyCode:'GBP';
        setSelectedCurrency(latest); 
      }
    }
    // if(getCurrencyRate){
    //   localStorage.setItem('currency-rate',getCurrencyRate?.data?.rate);
    //   localStorage.setItem('currency-code',getCurrencyRate?.data?.code);
    // }
    if(updateCurrencyRate){
      localStorage.setItem('currency-rate',updateCurrencyRate?.data?.rate);
      localStorage.setItem('currency-code',updateCurrencyRate?.data?.code);
      
      setTimeout(()=>{
        window.location.reload();
      },500);
    }

    console.log("data : ", data, ", error :", error, ", loading : ", loading);
  }, [data, error, loading,updateCurrencyRate]);


  // Handle data update when `data` changes
  useEffect(() => {
    if (cartData) {
      setCartItemCount(cartData?.count || 0); // Update cart item count if available
    }
  });

  const updateCurrency = (code) => {
    console.log(`Currency updated to: ${code}`);
    const currencyCode  = localStorage.getItem('currency-code');
    const latest = (currencyCode==null)?currencyCode:code;
    setSelectedCurrency(latest); // Update selected currency
    setCurrencyCode(latest)
  }

  const HandleLogout = () => {
    localStorage.clear('/')
    navigate('/login')
  }

  return (
    <header className="header">
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand ><Link to="/"><img src={logo} className="App-logo" alt="logo" /></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
            >
              <NavDropdown title="Courses" id="navbarScrollingDropdown">
              {/* <NavDropdown.Item as={Link} to="#">Categories</NavDropdown.Item>
              <NavDropdown.Divider /> */}
              <NavDropdown.Item as={Link} to="/online-course">Online Course</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Form className="d-flex flex-grow-1">
              <InputGroup>
                <InputGroup.Text ><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Form>

            {useData ?
                <>
                <div className="d-lg-flex ms-auto my-3 my-lg-0">
                    <Nav>
                      <Nav.Link href="#deets">Pricing</Nav.Link>
                      <Nav.Link eventKey={2} href="#memes">Career Path</Nav.Link>
                      <Nav.Link eventKey={2} href="#memes">Bundles</Nav.Link>
                      <Nav.Link eventKey={2} href="#memes">Uplatz Business</Nav.Link>
                    </Nav>
                    <div className='userIcons'>
                      <Link to="/#"><span><FontAwesomeIcon icon={faHeart} /></span></Link>
                      <Link to="/my-cart"><span><FontAwesomeIcon icon={faCartPlus} />
                      
                      {cartItemCount > 0 && (
                            <span style={{
                                position: 'absolute',
                                top: '-2px',
                                right: '166px',
                                backgroundColor: 'red',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '4px 9px',
                                fontSize: '12px',
                            }}>
                                {cartItemCount}
                            </span>
                        )}
                      </span></Link>
                      <Link to="/#"><span><FontAwesomeIcon icon={faBell} /></span></Link>
                      <Dropdown className="user d-inline-block" style={{ paddingRight: "3px" }}>
                      {useData ?
                          <>
                            <Dropdown.Toggle  id="dropdown-basic" align="end">{user?.data.fullname}</Dropdown.Toggle>
                          </>
                          :
                          <></>
                        }
                          <Dropdown.Menu align="end">
                          <Dropdown.Item as={Link} to="/my-profile">My Profile</Dropdown.Item>
                          <NavDropdown.Divider />
                          <Dropdown.Item as={Link} to="/my-course-list">My course</Dropdown.Item>
                          <NavDropdown.Divider />
                          <Dropdown.Item onClick={HandleLogout} >LogOut</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </div>
                  </div>
                  <Dropdown className="user d-inline-block"> 
                    <Dropdown.Toggle className="GreyBtn"  id="dropdown-basic" align="end">
                    <FontAwesomeIcon icon={faGlobe} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className='currency-drop-down' title={selectedCurrency}>
                      {currencyData && currencyData?.map(item => (
                        <Dropdown.Item  key={item.code} onClick={() => updateCurrency(item.code)}>
                            {item.currency}
                            {selectedCurrency == item.code && (
                              <span style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            )}
                        </Dropdown.Item>
                      ))}                      
                    </Dropdown.Menu>
                  </Dropdown>                  
                </>
                :
                <>
                  <div className="d-lg-flex ms-auto my-3 my-lg-0">
                    <Nav>
                      <Nav.Link href="#deets">Pricing</Nav.Link>
                      <Nav.Link eventKey={2} href="#memes">Career Path</Nav.Link>
                      <Nav.Link eventKey={2} href="#memes">Bundles</Nav.Link>
                      <Nav.Link eventKey={2} href="#memes">Uplatz Business</Nav.Link>
                    </Nav>
                      <>
                        <Link to="/login"><Button className='me-1 brdrBtn' variant="primary">LOGIN</Button></Link>
                        <Link to="/signup"><Button className='me-1 blueBtn' variant="secondary">SIGNUP</Button></Link>
                      </>
                  </div>
                  <Dropdown className="user d-inline-block" >
                    <Dropdown.Toggle className="GreyBtn"  id="dropdown-basic" align="end">
                      <FontAwesomeIcon icon={faGlobe} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='currency-drop-down' align="end" title={selectedCurrency}>
                      {currencyData && currencyData?.map(item => (
                        <Dropdown.Item key={item.code} onClick={() => updateCurrency(item.code)}>
                            {item.currency}
                            {selectedCurrency == item.code && (
                              <span style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            )}
                        </Dropdown.Item>
                      ))}                      
                    </Dropdown.Menu>
                  </Dropdown>               
                </>
              }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;