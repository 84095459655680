// src/hooks/useApi.js
import { useState, useCallback } from 'react';
import { apiCall } from '../utils/api';

const useApi = (url, method = 'POST') => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const makeApiCall = useCallback(async (body) => {
    setLoading(true);
    const result = await apiCall(url, method, body);
    setData(result.data);
    setError(result.error);
    setLoading(false);
  }, [url, method]);

  return { data, error, loading, makeApiCall };
};

export default useApi;
