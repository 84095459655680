import { render, screen } from '@testing-library/react';
import '../App.css';
import HeaderClass from '../component/header-class';
import Footer from '../component/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Table } from 'react-bootstrap';

import videoImg from '../img/videoImg.png';
import BestDeller from '../img/best-seller.png';
import Tutor from '../img/tutor.png';
import calendar from '../img/calendar.png';
import globe from '../img/globe.png';
import certi from '../img/certi.png';
import Demand from '../img/demand.png';
import Access from '../img/access.png';
import Assignment from '../img/assignment.png';
import Infinity from '../img/infinity.png';
import Article from '../img/article.png';
import Trophy from '../img/trophy.png';
import Download from '../img/download.png';
import ticks from '../img/ticks.png';
import videos from '../img/video.png';
import courseImg from '../img/course-img.jpg';
import PlaySmall from '../img/play-small.png';
import ReadBefore from '../img/read-before.png';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faEye, } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStars } from '@fortawesome/free-regular-svg-icons';
import { faCircleQuestion, faClock } from '@fortawesome/free-regular-svg-icons';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from 'react';
import useApi from '../Hooks/useApi';
import { useLocation } from 'react-router-dom';
import { PICURL, stripHtml, VIDEOURL ,ContentDisplay,DynamicAccordionFromHtml,DynamicAccordionFromHtml2} from '../utils/helper';
import Header from '../component/header';
import { useRef } from 'react';
import LoaderComponent from '../component/loader';

function ClassDetails() {
    const location = useLocation();
    const { data, error, loading, makeApiCall } = useApi('online-it-course-detail', 'POST', []);

      // State to track the currently playing video
    const [resourceVideo, setResourceVideo] = useState(null);
    const [couserDetailData, setCourseDetailData] = useState([]);
    const [hasVideo, setHasVideo] = useState(false);
    const [mediaURL, setMediaURL] = useState("");
    const [resourceMediaURL, setResourceMediaURL] = useState("");
    const videoRef = useRef(null);

    useEffect(() => {
        // Make the API call
        makeApiCall({ course_id: location.state.course_id });
    }, [location.state.course_id]);

    useEffect(() => {
        if (data) {
            setCourseDetailData(data?.data);
            console.log("data?.data : ",data?.data)
            if (resourceMediaURL) {
                setMediaURL(resourceMediaURL);
            } else if (data?.data.top_first_video) {
                setHasVideo(true);
                setMediaURL(VIDEOURL + data?.data.top_first_video.video);
            } else {
                setMediaURL(PICURL + data?.data.picture);
            }
        }
        window.scrollTo(0, 0);
    }, [data, resourceMediaURL]);


    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.play();
        }
    }, [mediaURL]);

    const setResourseVideo = (video) => {
        const newVideoURL = VIDEOURL + video;
        console.log("Setting new video URL:", newVideoURL);
        setResourceMediaURL(newVideoURL);
    };

    // State to keep track of expanded/collapsed text for each item
    const [expandedItems, setExpandedItems] = useState({});

    // Handle toggle for "Read More"/"Read Less"
    const toggleReadMore = (id) => {
        setExpandedItems((prev) => ({
        ...prev,
        [id]: !prev[id], // Toggle the expanded state of the clicked item
        }));
    };


    return (
        <>
            <LoaderComponent loader={loading} />
            <Container fluid>
                <div classNavme='BetList'>
                    <Header />
                </div>
                <Row>
                    <Col xs={12} md={8} lg={9}>
                        {
                            resourceMediaURL ?
                                <video  ref={videoRef} controls oncontextmenu="return false;" controlsList="nodownload"  poster={PICURL + couserDetailData?.top_first_video.thumb} style={{ maxWidth: '100%',height:'750px' }}>
                                    <source src={mediaURL} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                :
                                !hasVideo ? (
                                    <img src={mediaURL} alt="Course Media" style={{ maxWidth: '100%' }} />
                                ) : (
                                    <>
                                        <video controls oncontextmenu="return false;" controlsList="nodownload" poster={PICURL + couserDetailData?.top_first_video.thumb} style={{ maxWidth: '100%' ,height:'750px' }}>
                                            <source src={mediaURL} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </>
                                )
                        }
                        <h4 className='f-20 fw-700 m-3 pb-3'>Code Your Very Own Dynamic Websites by Learning PHP Through Real-World Application
                            & Examples</h4>
                        <div className='CourseDetails mx-3 mb-5'>
                            <Tabs fill defaultActiveKey="overview" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="overview" title="About">
                                    <div className='detailBox'>
                                        <h3>Description</h3>
                                        <ContentDisplay content={couserDetailData?.details} />
                                    </div>
                                    <div className='detailBox'>
                                        <h3>This course includes:</h3>
                                        <Row className='mt-3'>
                                            <Col xs={12} md={6}>
                                                <p><img src={Demand} /> 34 hours on-demand video</p>
                                                <p><img src={Assignment} /> Assignments</p>
                                                <p><img src={Article} /> 2 articles</p>
                                                <p><img src={Download} /> 35 downloadable resources</p>

                                            </Col>
                                            <Col xs={12} md={6}>
                                                <p><img src={Access} /> Access on mobile and TV</p>
                                                <p><img src={Infinity} /> Full lifetime access</p>
                                                <p><img src={Trophy} /> Certificate of completion</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                                <Tab eventKey="details" title="Objectives" >
                                    <div className='detailBox mb-0'>
                                        <ContentDisplay content={couserDetailData?.jobs} />
                                    </div>
                                </Tab>
                                <Tab eventKey="content" title="Syllabus">
                                    <div className='detailBox'>
                                        <h3>Course Content</h3>
                                        <p><ContentDisplay content={couserDetailData?.syllabus} /></p>
                                    </div>
                                </Tab>

                                <Tab eventKey="instructor" title="Certification" >
                                    <p><ContentDisplay content={couserDetailData?.certification} /></p>
                                </Tab>
                                <Tab eventKey="review" title="Career & Jobs" >
                                    <p><ContentDisplay content={couserDetailData?.career_path_and_jobs} /></p>
                                </Tab>
                                <Tab eventKey="review mb-0" title="Review" >
                                    <div className='detailBox reviewBox'>
                                        <h3>Review</h3>

                                        <Row className='mx-0'>
                                            <Col xs={12} md={3} className='px-0'>
                                                <div className='total-rating'>
                                                    <p className='redTitle'>Course Ratings</p>
                                                    <p className='totalRate'>4.5</p>
                                                    <p className='ratingStar'>
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col xs={12} md={9} className='pe-0'>
                                                <p className='d-flex mb-2'>
                                                    <span className='ratStars'>
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                    </span>
                                                    <span className='flex-grow-1 rate-strip'>
                                                        <span><span style={{ width: '63%' }}></span></span>
                                                    </span>
                                                    <span>63%</span>
                                                </p>

                                                <p className='d-flex mb-2'>
                                                    <span className='ratStars'>
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                    </span>
                                                    <span className='flex-grow-1 rate-strip'>
                                                        <span><span style={{ width: '29%' }}></span></span>
                                                    </span>
                                                    <span>29%</span>
                                                </p>

                                                <p className='d-flex mb-2'>
                                                    <span className='ratStars'>
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                    </span>
                                                    <span className='flex-grow-1 rate-strip'>
                                                        <span><span style={{ width: '15%' }}></span></span>
                                                    </span>
                                                    <span>15%</span>
                                                </p>

                                                <p className='d-flex mb-2'>
                                                    <span className='ratStars'>
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                    </span>
                                                    <span className='flex-grow-1 rate-strip'>
                                                        <span><span style={{ width: '12%' }}></span></span>
                                                    </span>
                                                    <span>12%</span>
                                                </p>

                                                <p className='d-flex mb-2'>
                                                    <span className='ratStars'>
                                                        <FontAwesomeIcon icon={faStar} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                        <FontAwesomeIcon icon={faStars} className='d-inline-block me-1 align-text-top' />
                                                    </span>
                                                    <span className='flex-grow-1 rate-strip'>
                                                        <span><span style={{ width: '5%' }}></span></span>
                                                    </span>
                                                    <span>02%</span>
                                                </p>

                                            </Col>

                                        </Row>


                                    </div>
                                </Tab>
                            </Tabs>
                        </div>



                        <Row className='mt-5 mx-2 mx-lg-5'>
                            <Col xs={12} md={8} className='interview'>
                                <h2 className='blue f-20 fw-700 text-capitalize'>Interview Prep</h2>
                                <DynamicAccordionFromHtml2 htmlContent={couserDetailData?.resouces} />
                            </Col>
                            {/* <Col xs={12} md={6} className='quiz ps-3'>
                                <h2 className='blue f-20 fw-700 text-capitalize'>Quiz</h2>
                                <Row className='mx-0'>
                                    <Col xs={12} md={12} className='brdr-btm  px-0'>
                                        <p className='topic float-start mb-2'><FontAwesomeIcon icon={faCircleQuestion} className='d-inline-block me-2 align-text-top' />Quiz Name</p>
                                        <span className='time float-end'>
                                            <Form.Check type="radio" aria-label="radio 1" />
                                        </span>
                                    </Col>
                                    <Col xs={12} md={12} className='brdr-btm  px-0'>
                                        <p className='topic float-start mb-2'><FontAwesomeIcon icon={faCircleQuestion} className='d-inline-block me-2 align-text-top' />Quiz Name</p>
                                        <span className='time float-end'>
                                            <Form.Check type="radio" aria-label="radio 1" />
                                        </span>
                                    </Col>
                                    <Col xs={12} md={12} className='brdr-btm  px-0'>
                                        <p className='topic float-start mb-2'><FontAwesomeIcon icon={faCircleQuestion} className='d-inline-block me-2 align-text-top' />Quiz Name</p>
                                        <span className='time float-end'>
                                            <Form.Check type="radio" aria-label="radio 1" />
                                        </span>
                                    </Col>
                                    <Col xs={12} md={12} className='brdr-btm  px-0'>
                                        <p className='topic float-start mb-2'><FontAwesomeIcon icon={faCircleQuestion} className='d-inline-block me-2 align-text-top' />Quiz Name</p>
                                        <span className='time float-end'>
                                            <Form.Check type="radio" aria-label="radio 1" />
                                        </span>
                                    </Col>
                                    <Col xs={12} md={12} className='brdr-btm  px-0'>
                                        <p className='topic float-start mb-2'><FontAwesomeIcon icon={faCircleQuestion} className='d-inline-block me-2 align-text-top' />Quiz Name</p>
                                        <span className='time float-end'>
                                            <Form.Check type="radio" aria-label="radio 1" />
                                        </span>
                                    </Col>
                                    <Col xs={12} md={12} className='brdr-btm  px-0'>
                                        <p className='topic float-start mb-2'><FontAwesomeIcon icon={faCircleQuestion} className='d-inline-block me-2 align-text-top' />Quiz Name</p>
                                        <span className='time float-end'>
                                            <Form.Check type="radio" aria-label="radio 1" />
                                        </span>
                                    </Col>

                                </Row>
                            </Col> */}
                        </Row>

                        <Row className='mt-5 justify-content-center mb-5 mx-2 mx-lg-0'>
                            <Col xs={12} md={12} lg={10}>
                                <h2 className='blue pb-2'>FAQ</h2>
                                <DynamicAccordionFromHtml htmlContent={couserDetailData?.faqs} />
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} md={4} lg={3} className='classChapter pt-3'>
                        <h4 className='f-17 fw-700 brdr-btm pb-3  mx-3 mx-md-0'>All Courses </h4>
                        <Accordion defaultActiveKey="0" className='mx-3 mx-md-0'>
                            {couserDetailData?.videos?.length > 0 ?
                                couserDetailData?.videos?.map((section,index) => {
                                    return (
                                        < Accordion.Item eventKey={index.toString()} key={index} >
                                            <Accordion.Header>
                                                <div>
                                                    <h4 className='f-14 fw-bold mb-1'>Section {index+1} : {section?.group_name}</h4>
                                                    <p>
                                                        <span>{section?.videos.length}  |  {couserDetailData?.duration} min</span>
                                                        <span className="progressBar">
                                                            <span><span></span></span>
                                                        </span>
                                                        <span>50%</span>
                                                    </p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {section?.videos?.map((video, index) => {
                                                    const isExpanded = expandedItems[video.id]; // Check if the current item is expanded
                                                    const isPlaying = resourceVideo === video?.video;
                                                    return (
                                                        <Row className="brdr-bottom pb-2 mb-2" key={index}>
                                                            <Col xs={9} className="px-1">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`custom-switch-${index}`}
                                                                    label={`${index + 1}. ${video?.title}`}
                                                                    className="fw-bold"
                                                                />
                                                                <Button 
                                                                    variant={isPlaying ? 'success' : 'dark'} // Change color when playing
                                                                    size="sm"
                                                                    className="btn-thin" // Custom class to style the button
                                                                    active={isPlaying} // Mark as active if this video is playing
                                                                    onClick={() => setResourceVideo(video?.video)} // Set the selected video as playing
                                                                    >
                                                                    <a style={{ cursor: "pointer" }}
                                                                        onClick={() => setResourseVideo(video?.video)}
                                                                    >
                                                                        {isPlaying ? "Playing" : "Play Now"}
                                                                    </a>
                                                                </Button>
                                                            </Col>
                                                            <Col className="text-end" xs={3}>
                                                                {video?.duration} min
                                                            </Col>
                                                            <Col xs={12} className="px-1">
                                                                <p>
                                                                {isExpanded
                                                                    ? video?.details // Show full description if expanded
                                                                    : `${video?.details?.substring(0, 150)}...`} {/* Show truncated text */}
                                                                <button
                                                                    onClick={() => toggleReadMore(video.id)} // Toggle expand/collapse on click
                                                                    className="btn btn-link p-0"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {isExpanded ? 'Less' : 'More'}
                                                                </button>
                                                                </p>                                                            
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                                }
                                                </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                                : ""}
                        </Accordion>
                    </Col>
                </Row>
                <div classNavme='BetList'>
                    <Footer />
                </div>
            </Container >
        </>
    );
}

export default ClassDetails;